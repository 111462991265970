var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("card", { staticClass: "main-content" }, [
    _c("div", { staticClass: "row mt-4 my-2" }, [
      _c(
        "div",
        { staticClass: "col-12 my-2" },
        [
          _vm._l(_vm.formfiltersettings, function (formfiltersetting, findex) {
            return _c("div", [
              _c(
                "div",
                { staticClass: "shadow p-3 mb-5 bg-white rounded" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "badge badge-primary text-wrap",
                      staticStyle: { width: "2rem" },
                    },
                    [_vm._v(" NO " + _vm._s(findex + 1) + " ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-danger float-right",
                      staticStyle: {
                        padding: "2px",
                        "padding-right": "8px",
                        "padding-left": "8px",
                      },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteFormFilterSetting(findex)
                        },
                      },
                    },
                    [_vm._v("Delete")]
                  ),
                  _c("div", { staticClass: "row mt-4 my-2" }, [
                    _c("div", { staticClass: "form-group col-5" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("__ExternalapiName"))),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: formfiltersetting.externalapiname,
                              expression: "formfiltersetting.externalapiname",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                formfiltersetting,
                                "externalapiname",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.allExternalApiName, function (value, index) {
                          return _c(
                            "option",
                            {
                              key: "externalapiname-" + index,
                              domProps: { value: value },
                            },
                            [_vm._v(" " + _vm._s(value) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "form-group col-5" }, [
                      _c("label", [_vm._v("App")]),
                      false
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "col-6 mb-0",
                                  attrs: { id: "appId-" + findex + "-title" },
                                },
                                [_vm._v(_vm._s(formfiltersetting.appid))]
                              ),
                              _c(
                                "span",
                                {
                                  attrs: { appid: "appId-" + findex + "-edit" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editUI("appId-" + findex)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-pencil-alt cursor-pointer pl-1",
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: formfiltersetting.appid,
                            expression: "formfiltersetting.appid",
                          },
                        ],
                        staticClass: "form-control element-hide",
                        attrs: {
                          type: "text",
                          id: "appId-" + findex + "-input",
                        },
                        domProps: { value: formfiltersetting.appid },
                        on: {
                          keypress: function ($event) {
                            return _vm.confirmUIChangeKey(
                              $event,
                              "appId-" + findex
                            )
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              formfiltersetting,
                              "appid",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: formfiltersetting.appid,
                              expression: "formfiltersetting.appid",
                            },
                          ],
                          staticClass: "form-control col-7",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                formfiltersetting,
                                "appid",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.externals, function (external) {
                          return _c(
                            "option",
                            { domProps: { value: external.app_id } },
                            [_vm._v(_vm._s(external.app_name))]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row mt-4 my-2" }, [
                    _c("label", { staticClass: "col-3" }, [
                      _vm._v(_vm._s(_vm.$t("__FilterType"))),
                    ]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: formfiltersetting.filtertype,
                            expression: "formfiltersetting.filtertype",
                          },
                        ],
                        staticClass: "form-control col-7",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              formfiltersetting,
                              "filtertype",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "and" } }, [
                          _vm._v("and"),
                        ]),
                        _c("option", { attrs: { value: "or" } }, [
                          _vm._v("or"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "row mb-2 align-items-center" }, [
                    _c("div", { staticClass: "row col-6 my-2" }, [
                      _c("p", { staticClass: "col-9 my-4" }, [
                        _c("b", [_vm._v(_vm._s(_vm.$t("__FilterField")))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "ml-1" }, [
                      _c(
                        "a",
                        {
                          staticClass: "text-info m-1",
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.addFilterFiled(findex)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-plus text-info" })]
                      ),
                    ]),
                  ]),
                  _vm._l(
                    formfiltersetting.filterFiledsObjectlize,
                    function (filterFiled, index) {
                      return _c(
                        "div",
                        {
                          key: findex + "-filter-" + index,
                          staticClass: "row",
                        },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: filterFiled.fieldType,
                                  expression: "filterFiled.fieldType",
                                },
                              ],
                              staticClass: "form-control col-3 ml-3",
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      filterFiled,
                                      "fieldType",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function ($event) {
                                    return this.$forceUpdate()
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: {
                                    value: "",
                                    disabled: "",
                                    selected: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("__filterTypeSelectOptional"))
                                  ),
                                ]
                              ),
                              _c("option", { attrs: { value: "date" } }, [
                                _vm._v(_vm._s(_vm.$t("__dateType"))),
                              ]),
                              _c("option", { attrs: { value: "dateRange" } }, [
                                _vm._v(_vm._s(_vm.$t("__dateRange"))),
                              ]),
                              _c("option", { attrs: { value: "dataSource" } }, [
                                _vm._v(_vm._s(_vm.$t("__dataSource"))),
                              ]),
                              _c("option", { attrs: { value: "dataCustom" } }, [
                                _vm._v(_vm._s(_vm.$t("__Custom"))),
                              ]),
                            ]
                          ),
                          filterFiled.fieldType == "dataSource"
                            ? _c("div", { staticClass: "col-6 ml-3 mr-3" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col" }, [
                                    _vm._v("APP ID"),
                                  ]),
                                  _c("div", { staticClass: "col" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: filterFiled.app_id,
                                            expression: "filterFiled.app_id",
                                          },
                                        ],
                                        staticClass: "form-control col-7",
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              filterFiled,
                                              "app_id",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.externals,
                                        function (external) {
                                          return _c(
                                            "option",
                                            {
                                              domProps: {
                                                value: external.app_id,
                                              },
                                            },
                                            [_vm._v(_vm._s(external.app_name))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col" }, [
                                    _vm._v("篩選欄位"),
                                  ]),
                                  _c("div", { staticClass: "col" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: filterFiled.filterField,
                                            expression:
                                              "filterFiled.filterField",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              filterFiled,
                                              "filterField",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: {
                                              value: "",
                                              disabled: "",
                                              selected: "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "__UserVariableSelectOptional"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          _vm.allVariables,
                                          function (value, index) {
                                            return _c(
                                              "option",
                                              {
                                                key: "variable-" + index,
                                                domProps: { value: value },
                                              },
                                              [_vm._v(_vm._s(value))]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col" }, [
                                    _vm._v("顯示欄位"),
                                  ]),
                                  _c("div", { staticClass: "col" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: filterFiled.displayField,
                                            expression:
                                              "filterFiled.displayField",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              filterFiled,
                                              "displayField",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: {
                                              value: "",
                                              disabled: "",
                                              selected: "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "__UserVariableSelectOptional"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          _vm.allVariables,
                                          function (value, index) {
                                            return _c(
                                              "option",
                                              {
                                                key: "variable-" + index,
                                                domProps: { value: value },
                                              },
                                              [_vm._v(_vm._s(value))]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]),
                              ])
                            : filterFiled.fieldType == "dataCustom"
                            ? _c(
                                "div",
                                { staticClass: "col-6 ml-3 mr-3" },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _vm._v("欄位名稱"),
                                    ]),
                                    _c("div", { staticClass: "col" }, [
                                      _vm._v("欄位值 "),
                                      _c(
                                        "button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.addCustomItems(index)
                                            },
                                          },
                                        },
                                        [_vm._v("add")]
                                      ),
                                      _c("button", [_vm._v("delete")]),
                                    ]),
                                  ]),
                                  _vm._l(
                                    filterFiled.customItems,
                                    function (customItem, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: "customItem-" + index,
                                          staticClass: "row",
                                        },
                                        [
                                          _c("div", { staticClass: "col" }, [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      customItem.filterField,
                                                    expression:
                                                      "customItem.filterField",
                                                  },
                                                ],
                                                staticClass:
                                                  "form-control col-7",
                                                on: {
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      customItem,
                                                      "filterField",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                },
                                              },
                                              _vm._l(
                                                _vm.allVariables,
                                                function (value, index) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: "variable-" + index,
                                                      domProps: {
                                                        value: value,
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(value))]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]),
                                          _c("div", { staticClass: "col" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: customItem.filterValue,
                                                  expression:
                                                    "customItem.filterValue",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: { type: "text" },
                                              domProps: {
                                                value: customItem.filterValue,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    customItem,
                                                    "filterValue",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _c("div", { staticClass: "col-6 ml-3 mr-3" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: filterFiled.filterField,
                                        expression: "filterFiled.filterField",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          filterFiled,
                                          "filterField",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          value: "",
                                          disabled: "",
                                          selected: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "__UserVariableSelectOptional"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.allVariables,
                                      function (value, index) {
                                        return _c(
                                          "option",
                                          {
                                            key: "variable-" + index,
                                            domProps: { value: value },
                                          },
                                          [_vm._v(_vm._s(value))]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                          _c("div", { staticClass: "ml-1" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-danger m-1",
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFilterFiled(findex, index)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-minus text-danger",
                                }),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "ml-1" }, [
                            index ==
                            formfiltersetting.filterFiledsObjectlize.length - 1
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "text-info m-1",
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addFilterFiled(findex)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-plus text-info",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }
                  ),
                  _c("div", { staticClass: "row mb-2 align-items-center" }, [
                    _c("div", { staticClass: "row col-6 my-2" }, [
                      _c("p", { staticClass: "col-9 my-4" }, [
                        _c("b", [_vm._v(_vm._s(_vm.$t("__ShowField")))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "ml-1" }, [
                      _c(
                        "a",
                        {
                          staticClass: "text-info m-1",
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.addShowFiled(findex)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-plus text-info" })]
                      ),
                    ]),
                  ]),
                  _vm._l(
                    formfiltersetting.showFiledsObjectlize,
                    function (showFiled, index) {
                      return _c(
                        "div",
                        { key: findex + "-show-" + index, staticClass: "row" },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: showFiled.userVariable,
                                  expression: "showFiled.userVariable",
                                },
                              ],
                              staticClass: "form-control col-6 ml-3 mr-3",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    showFiled,
                                    "userVariable",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: {
                                    value: "",
                                    disabled: "",
                                    selected: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("__UserVariableSelectOptional")
                                    )
                                  ),
                                ]
                              ),
                              _vm._l(_vm.allVariables, function (value, index) {
                                return _c(
                                  "option",
                                  {
                                    key: "variable-" + index,
                                    domProps: { value: value },
                                  },
                                  [_vm._v(_vm._s(value))]
                                )
                              }),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "ml-1" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-danger m-1",
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteShowFiled(findex, index)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-minus text-danger",
                                }),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "ml-1" }, [
                            index ==
                            formfiltersetting.showFiledsObjectlize.length - 1
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "text-info m-1",
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addShowFiled(findex)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-plus text-info",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }
                  ),
                ],
                2
              ),
            ])
          }),
          _c(
            "div",
            {
              staticClass: "row pt-4 add-btn-input cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.addFormFilterSetting()
                },
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "mx-auto my-3",
                  attrs: { href: "javascript:void(0)" },
                },
                [_c("i", { staticClass: "fas fa-plus" })]
              ),
            ]
          ),
        ],
        2
      ),
    ]),
    _c(
      "button",
      {
        staticClass: "btn btn-outline-success pull-right",
        attrs: { "data-translate": "" },
        on: {
          click: function ($event) {
            return _vm.updateFormScenarioFilterConfig()
          },
        },
      },
      [_vm._v(" Update ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }