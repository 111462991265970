<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade bd-example-modal-lg"
      id="nodeModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <!-- FormScenario version -->
      <div class="modal-dialog modal-lg FormScenario" role="document">
        <div
          class="modal-content tag-modal"
          style="width: 1000px"
        >
          <div class="modal-header" style="align-items: center">
            <span v-if="this.nodeData.nodeId != ''">
              Form {{ $t("__modalTitleNodeId") }}: {{ this.nodeData.nodeId }}
            </span>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <!-- ボディ -->
          <div class="modal-body">

            <!-- Tag -->
            <div class="row px-3 py-2">
              <ul
                class="tags"
                id="node-tag-area"
                v-for="(tag, index) in formInput.tags"
                :key="index"
              >
                <li>
                  <a
                    href="javascript:void(0)"
                    class="tag"
                    @click="deleteTag(tag)"
                    :class="tag.color"
                    id="newLevelTag"
                    >{{ tag.name }}</a
                  >
                </li>
              </ul>
              <button
                class="btn btn-outline-secondary btn-sm ml-auto"
                data-toggle="modal"
                data-target="#tag"
                @click="showExistTag()"
              >
                {{ $t("__Addlabel") }}
              </button>
            </div>
            <!-- Node SubTitle -->
            <div class="row px-3 mb-1">
              <p
                v-if="formInput.subTitle !== ''"
                class="text-bold"
                id="node-subTitle"
              >
                {{ formInput.subtitle }}
              </p>
              <p
                v-if="formInput.subtitle == ''"
                class="text-bold"
                id="node-subTitle"
              >
                Subtitle
              </p>
              <span id="node-subTitle-edit" @click="editSubTitle()">
                <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
              </span>
              <input
                type="text"
                class="form-control element-hide"
                id="node-subTitle-input"
                v-model="formInput.subtitle"
                @keypress="editSubTitleDone($event)"
              />
            </div>


            <!-- 会話ノード -->
            <div
              class="row border p-3"
              style="height: 65vh; overflow: auto"
            >

              <div class="D w-100" id="v-pills-tabContent" >
                <!-- Form Setting -->
                <div
                  class="tab-pane fade show active"
                  id="v-pills-formsetting"
                  role="tabpanel"
                  aria-labelledby="v-pills-formsetting-tab"
                >
                  <div class="pt-5">
                    <!-- Title name -->
                    <label>{{ $t("__utilsType") }}</label>
                    <select class="form-control col-8" v-model="formInput.template.form.type" @change="onTypeChange">
                      <option value="label">{{ $t("__label") }}</option>
                      <option value="caption">{{ $t("__caption") }}</option>
                      <option value="dropdown">{{ $t("__dropdown") }}</option>
                      <option value="dropdown2">{{ $t("__dropdown2") }}</option>
                      <option value="dropdownMerge">{{ $t("__dropdownMerge") }}</option>
                      <option value="text">{{ $t("__text") }}</option>
                      <option value="textarea">{{ $t("__textarea") }}</option>
                      <option value="button">{{ $t("__buttonSingle") }}</option>
                      <option value="buttonMultiple">{{ $t("__buttonMultiple") }}</option>
                      <option value="date">{{ $t("__date") }}</option>
                      <option value="time">{{ $t("__time") }}</option>
                      <option value="datetime-local">{{ $t("__datetime_local") }}</option>
                      <option value="email">{{ $t("__email") }}</option>
                      <option value="number">{{ $t("__number") }}</option>
                      <option value="password">{{ $t("__password") }}</option>
                      <option value="tel">{{ $t("__tel") }}</option>
                      <option value="url">{{ $t("__url") }}</option>
                      <option value="search">{{ $t("__search") }}</option>
                      <option value="color">{{ $t("__color") }}</option>
                      <option value="range">{{ $t("__range") }}</option>
                      <option value="file">{{ $t("__file") }}</option>
                      <option value="multiple">{{ $t("__multiple") }}</option>
                      <option value="checkbox">{{ $t("__checkbox") }}</option>
                      <option value="radio">{{ $t("__radio") }}</option>
                      <option value="hidden">{{ $t("__hidden") }}</option>
                      <option value="month">{{ $t("__month") }}</option>
                      <option value="week">{{ $t("__week") }}</option>
                      <option value="submit">{{ $t("__submitbutton") }}</option>
                      <option value="reset">{{ $t("__reset") }}</option>
                      <option value="image">{{ $t("__image") }}</option>
                      <option value="datalist">{{ $t("__datalist") }}</option>
                    </select>

                    <div>
                      <br>
                      <span class="text-danger">*</span>
                      {{ $t("__requiredItem") }}
                    </div>

                    <div class="form-row px-4">
                      <div class="form-group col-2">
                        <label for="isRequiredCheckbox" class="form-check-label">是否啟用</label>
                        <input type="checkbox" id="enableCheckbox"
                          v-model="formInput.template.form.enable"
                          class="form-control"
                          style="transform: scale(0.5); margin-right: 10px;">
                      </div>
                      <div class="form-group col-2" v-if="formInput.template.form.type !== 'submit'">
                        <label for="enableRenderCheckbox" class="form-check-label">是否隱藏</label>
                        <input type="checkbox" id="enableRenderCheckbox"
                          v-model="formInput.template.form.enableRender"
                          class="form-control"
                          style="transform: scale(0.5); margin-right: 10px;">
                      </div>

                      </div>
                    <!-- Form / input -->
                    <div
                      v-if="formInput.template.form.enable== true"
                      class="border p-2 mb-2"
                    >
                      <br />

                      <!-- 標題名稱-->
                      <div class="form-row px-4" v-if="formFieldVisible(formInput.template.form.type,'button','dropdown','dropdown2','buttonMultiple','date','dropdownMerge','checkbox','datalist','radio','text')">
                        <!-- Form / title -->
                        <div class="form-group col-4" >
                          <label
                            ><span class="text-danger">*</span>
                            {{ $t("__titlename") }}</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="formInput.template.form.title"
                          />
                        </div>
                      </div>
                      <!-- appId -->
                      <div class="form-row px-4" v-if="formFieldVisible(formInput.template.form.type,'button','buttonMultiple','dropdown','dropdown2','dropdownMerge','checkbox','datalist','radio','submit')">

                        <!-- Form / AppId -->
                        <div
                          :class="{
                            'form-group col-8': formInput.template.form.type === 'checkbox',
                            'form-group col-4': formInput.template.form.type !== 'checkbox'
                          }"
                        >
                          <label>App ID</label>
                          <input
                            type="hidden"
                            class="form-control"
                            v-model="formInput.template.form.appid"
                          />
                          <select  class="form-control col-7" v-model="formInput.template.form.appid">
                            <!-- 初期値 -->
                            <!--</option> 選項and / or-->
                            <option :value="external.app_id" v-for="external in externals">{{external.app_name}}</option>
                          </select>
                        </div>
                        </div>
                      <!-- Form / 是否必填 -->
                      <div class="form-row px-4" v-if="formFieldVisible(formInput.template.form.type,'button','dropdown','dropdown2','dropdownMerge','checkbox','radio','button','datalist','buttonMultiple','text')">

                        <div class="form-group col-2" v-if="formInput.template.form.type !== 'submit'">
                            <label class="form-check-label" for="isRequiredCheckbox">{{ $t("__requiredField") }}</label>
                            <input
                              type="checkbox"
                              class="form-control"
                              id="isRequiredCheckbox"
                              v-model="formInput.template.form.isRequired"
                              style="transform: scale(0.5); margin-right: 10px;"
                            />

                        </div>
                      </div>

                      <!-- 啟用新增值 / 啟用多項輸入 / 欄位描述[?] -->
                      <div class="form-row px-4" v-if="false && formFieldVisible(formInput.template.form.type,'button','dropdown','dropdown2','dropdownMerge','checkbox','radio','button','datalist','buttonMultiple')">
                        <!-- Form / 啟用新增值 -->
                        <div class="form-group col-3">
                          <label class="form-check-label" for="allowAddValue">{{ $t("__allowAddValue") }}</label>
                            <input
                              type="checkbox"
                              class="form-control"
                              id="allowAddValue"
                              v-model="formInput.template.form.allowAddValue"
                              style="transform: scale(0.5);"
                            />
                        </div>
                        <!-- Form / 啟用多項輸入 -->
                        <div class="form-group col-3">
                          <label class="form-check-label" for="allowMultiple">{{ $t("__allowMultiple") }}</label>
                              <input
                                type="checkbox"
                                class="form-control"
                                id="allowMultiple"
                                v-model="formInput.template.form.allowMultiple"
                                style="transform: scale(0.5);"
                              />
                        </div>
                        <!-- Form / 欄位描述 -->
                        <div class="form-group col-4">
                          <label>{{ $t("__multipleSubformField") }}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="formInput.template.form.multiplesubformfield"
                          />
                        </div>

                      </div>


                      <!-- Form / 預設值 -->
                      <div class="form-row px-4" v-if="formFieldVisible(formInput.template.form.type,'button','dropdown','dropdown2','dropdownMerge','checkbox','radio','button','datalist','buttonMultiple','hidden')">

                        <div
                          :class="{
                            'form-group col-2': formInput.template.form.type === 'checkbox',
                            'form-group col-6': formInput.template.form.type !== 'checkbox'
                          }"
                        >
                          <label>{{ $t("__defaultValue") }}</label>
                          <!-- 當 type 是 checkbox 或 bool 時，顯示 true/false 選擇 -->
                          <input
                            v-if="formInput.template.form.type === 'checkbox'"
                            type="checkbox"
                            class="form-control"
                            v-model="formInput.template.form.defaultValue"
                            :checked="formInput.template.form.defaultValue"
                            @change="handleInputChange"
                            style="transform: scale(0.5); margin-right: 10px;"
                          />
                          <!-- 當 type 是其他值時，顯示一般輸入框 -->
                          <input v-else
                                type="text"
                                class="form-control"
                                v-model="formInput.template.form.defaultValue" />
                        </div>
                      </div>
                      <!-- Form / 預設今日 -->
                      <div class="form-row px-4" v-if="formFieldVisible(formInput.template.form.type,'date')">

                        <div
                          :class="{
                            'form-group col-2': formInput.template.form.type === 'checkbox',
                            'form-group col-6': formInput.template.form.type !== 'checkbox'
                          }"
                        >

                          <label class="form-check-label" for="isDefaultTodayCheckbox">{{ $t("__defaultToday") }}</label>
                            <input
                              type="checkbox"
                              class="form-control"
                              id="isDefaultTodayCheckbox"
                              v-model="formInput.template.form.isDefaultToday"
                              style="transform: scale(0.5); margin-right: 10px;"
                            />
                        </div>
                      </div>

                      <!-- 啟用節點篩選 / 設定篩選節點 -->
                      <div v-if="formFieldVisible(formInput.template.form.type,'button','dropdown','dropdown2','dropdownMerge','checkbox','radio','button','datalist','buttonMultiple')">
                        <!-- key / value -->
                        <div class="form-row px-4">
                            <!-- Form / key -->
                            <div
                              class="form-group col-5"
                            >
                              <label>
                                key</label>
                              <select class="form-control" v-model="formInput.template.form.key">
                                  <option value selected>
                                    {{ $t("__UserVariableSelectOptional") }}
                                  </option>
                                  <option
                                    v-for="(value, index) in allVariables"
                                    :value="value"
                                    :key="'variable-' + index"
                                  >
                                    {{ value }}
                                  </option>
                              </select>
                            </div>
                            <!-- Form / value -->
                            <div
                              class="form-group col-5"
                              v-if="accountType == 'line'"
                            >
                              <label>
                                value</label>
                              <select class="form-control" v-model="formInput.template.form.value">
                                  <option value selected>
                                    {{ $t("__UserVariableSelectOptional") }}
                                  </option>
                                  <option
                                    v-for="(value, index) in allVariables"
                                    :value="value"
                                    :key="'variable-' + index"
                                  >
                                    {{ value }}
                                  </option>
                              </select>
                            </div>
                          </div>

                        <!-- Form / 啟用排序 -->
                        <div class="form-row px-4">
                            <!-- Form / order -->
                            <!-- Form / 啟用排序 -->
                            <div
                              class="form-group col-2"
                            >
                            <label class="form-check-label" for="enableOrder"> {{ $t("__enableOrder") }}</label>
                            <input
                                type="checkbox"
                                class="form-control"
                                id="enableOrder"
                                v-model="formInput.template.form.enableorder"
                                style="transform: scale(0.5); margin-right: 10px;"
                              />
                            <!-- Form / order類型 -->
                            </div>
                            <div v-if="formInput.template.form.enableorder == true"
                               class="form-group col-3">
                              <label>
                                {{ $t("__ordertype") }}</label>
                              <select class="form-control" v-model="formInput.template.form.ordertype">
                                  <option value="asc" selected>
                                    {{ $t("__AscendingOrder") }}
                                  </option>
                                  <option value="desc">{{ $t("__DescendingOrder") }}</option>
                              </select>


                            </div>
                            <!-- Form / order欄位 -->
                            <div
                              v-if="formInput.template.form.enableorder == true"
                              class="form-group col-5"
                            >
                              <label>
                                {{ $t("__orderfield") }}</label>
                              <select class="form-control" v-model="formInput.template.form.orderfield">
                                  <option value selected>
                                    {{ $t("__UserVariableSelectOptional") }}
                                  </option>
                                  <option
                                    v-for="(value, index) in allVariables"
                                    :value="value"
                                    :key="'variable-' + index"
                                  >
                                    {{ value }}
                                  </option>
                              </select>
                            </div>
                          </div>

                        <div class="form-row px-4">
                          <!-- Form / 啟用節點篩選 -->
                          <div class="form-group col-2">
                              <label class="form-check-label" for="enableFilterNode">{{ $t("__enableFilterNode") }}</label>
                              <input
                                type="checkbox"
                                class="form-control"
                                id="enableFilterNode"
                                v-model="formInput.template.form.enablefilternode"
                                style="transform: scale(0.5); margin-right: 10px;"
                              />

                          </div>
                          <!-- Form / 設定篩選節點 -->
                          <div class="form-group col-4" v-if="formInput.template.form.enablefilternode == true">
                            <label>
                              {{ $t("__setFilterNode") }}
                            </label>
                            <select class="form-control" v-model="formInput.template.form.filternodeId">
                              <option value selected>
                                {{ $t("__NextNodeoptional") }}
                              </option>
                              <option :value="node.nodeId" v-for="(node, index) in allNodes" :key="index">
                                {{ node.title.substr(0, 25) }} {{ node.subtitle }}
                              </option>
                            </select>
                          </div>
                          <!-- Form / 設定子表單查找欄位 -->
                          <div
                          v-if="formInput.template.form.enablefilternode == true"
                          class="form-group col-4"
                        >
                          <label>
                            {{ $t("__subformlookupFiled") }}</label>
                          <select class="form-control" v-model="formInput.template.form.subformlookupfiled">
                              <option value selected>
                                {{ $t("__UserVariableSelectOptional") }}
                              </option>
                              <option
                                v-for="(value, index) in allVariables"
                                :value="value"
                                :key="'variable-' + index"
                              >
                                {{ value }}
                              </option>
                          </select>
                        </div>
                        </div>
                      <div class="form-row px-4">
                        <!-- Form / 啟用輔助選擇 -->
                        <div class="form-group col-2">
                          <label class="form-check-label" for="enableAuxiliarySelection">{{ $t("__enableAuxiliarySelection") }}</label>
                          <input
                            type="checkbox"
                            class="form-control"
                            id="enableAuxiliarySelection"
                            v-model="formInput.template.form.enableAuxiliarySelection"
                            style="transform: scale(0.5); margin-right: 10px;"
                          />
                        </div>

                        <!-- Form / 設定輔助選擇node -->
                        <div class="form-group col-4" v-if="formInput.template.form.enableAuxiliarySelection == true">
                          <label>{{ $t("__auxiliarySelectionNode") }}</label>
                          <select class="form-control" v-model="formInput.template.form.auxiliarySelectionNodeId">
                            <option value selected>
                              {{ $t("__NextNodeoptional") }}
                            </option>
                            <option :value="node.nodeId" v-for="(node, index) in allNodes" :key="index">
                              {{ node.title.substr(0, 25) }} {{ node.subtitle }}
                            </option>
                          </select>
                        </div>

                        <!-- Form / 輔助選擇欄位 -->
                        <div class="form-group col-4" v-if="formInput.template.form.enableAuxiliarySelection == true">
                          <label>{{ $t("__auxiliarySelectionField") }}</label>
                          <select class="form-control" v-model="formInput.template.form.auxiliarySelectionField">
                            <option value selected>
                              {{ $t("__UserVariableSelectOptional") }}
                            </option>
                            <option
                              v-for="(value, index) in allVariables"
                              :value="value"
                              :key="'variable-' + index"
                            >
                              {{ value }}
                            </option>
                          </select>
                        </div>
                      </div>




                       <!-- 啟用額外寫入欄位 -->
                      <div class="form-row px-4">
                        <div class="form-group col-2">
                          <label class="form-check-label" for="enableAdditionalEntryField">{{ $t("__enableAdditionalEntryField") }}</label>
                          <input
                            type="checkbox"
                            class="form-control"
                            id="enableAdditionalEntryField"
                            v-model="formInput.template.form.allowAdditionalEntryField"
                            style="transform: scale(0.5);"
                          />
                        </div>
                      </div>

                      <!-- 顯示 Parameter 和 Variable 的表單 -->
                      <div class="row mb-2 align-items-center" v-if="formInput.template.form.allowAdditionalEntryField == true">
                        <div class="col-2 my-2">

                        </div>
                        <div class=" col-4 my-2">
                          <p class="col-9 my-2"><b>Parameter</b></p>
                        </div>
                        <p class="col-4 my-2"><b>Variable</b></p>
                        <a
                          href="javascript:void(0)"
                          class="text-info m-1"
                          @click="addAdditionalEntry()"
                        >
                          <i class="fas fa-plus text-info"></i>
                        </a>
                      </div>

                      <!-- 顯示已新增的 Parameter 和 Variable 配對 -->
                      <div
                        class="row mb-2"
                        v-if="formInput.template.form.allowAdditionalEntryField == true"
                        v-for="(entry, index) in formInput.template.form.additionalEntries"
                        :key="'entry-' + index"
                      >
                          <!-- Parameter 下拉選單 -->
                          <div class="col-2 my-2">

                          </div>
                          <div class="col-4 my-2">
                            <select
                              class="form-control"
                              v-model="entry.parameter"
                              :id="'additional-parameter-' + index"
                            >
                              <option value selected>{{ $t("__UserVariableSelectOptional") }}</option>
                              <option
                                v-for="(value, idx) in allVariables"
                                :value="value"
                                :key="'parameter-' + idx"
                              >
                                {{ value }}
                              </option>
                            </select>
                          </div>

                          <!-- Variable 下拉選單 -->
                          <div class="row col-5 my-2">
                            <select
                              class="form-control"
                              v-model="entry.variable"
                              :id="'additional-variable-' + index"
                            >
                              <option value selected>{{ $t("__UserVariableSelectOptional") }}</option>
                              <option
                                v-for="(value, idx) in allVariables"
                                :value="value"
                                :key="'variable-' + idx"
                              >
                                {{ value }}
                              </option>
                            </select>
                          </div>

                          <!-- 刪除按鈕 -->
                          <div class="col-1 my-2 d-flex align-items-center">
                            <button class="btn btn-sm btn-outline-danger" @click="deleteAdditionalEntry(index)">
                              <i class="fas fa-trash"></i>
                            </button>
                          </div>
                      </div>


                      </div>
                      <!-- Form / 啟用合併選單 -->
                      <div class="form-row px-4" v-if="formFieldVisible(formInput.template.form.type,'button','dropdown','dropdown2','dropdownMerge','checkbox','radio','button','datalist','buttonMultiple')">

                      <div class="form-group col-2" v-if="formFieldVisible(formInput.template.form.type,'button','dropdown','dropdown2','dropdownMerge','checkbox','radio','button','datalist','buttonMultiple')">
                        <label class="form-check-label" for="enableMergedMenu">{{ $t("__enableMergedMenu") }}</label>
                        <input
                          type="checkbox"
                          class="form-control"
                          id="enableMergedMenu"
                          v-model="formInput.template.form.enableMergedMenu"
                          style="transform: scale(0.5); margin-right: 10px;"
                        />
                      </div>

                      <!-- Form / 合併選單母項目 -->
                      <div class="form-group col-4" v-if="formInput.template.form.enableMergedMenu == true && formFieldVisible(formInput.template.form.type,'button','dropdown','dropdown2','dropdownMerge','checkbox','radio','button','datalist','buttonMultiple')">
                        <label>{{ $t("__mergedMenuParentItem") }}</label>
                        <select class="form-control" v-model="formInput.template.form.mergedMenuParentId">
                          <option value selected>
                            {{ $t("__NextNodeoptional") }}
                          </option>
                          <option :value="node.nodeId" v-for="(node, index) in allNodes" :key="index">
                            {{ node.title.substr(0, 25) }} {{ node.subtitle }}
                          </option>
                        </select>
                      </div>

                      <!-- Form / 對應欄位 -->
                      <div class="form-group col-4" v-if="formInput.template.form.enableMergedMenu == true && formFieldVisible(formInput.template.form.type,'button','dropdown','dropdown2','dropdownMerge','checkbox','radio','button','datalist','buttonMultiple')">
                        <label>{{ $t("__mappingField") }}</label>
                        <select class="form-control" v-model="formInput.template.form.mappingField">
                          <option value selected>
                            {{ $t("__UserVariableSelectOptional") }}
                          </option>
                          <option
                            v-for="(value, index) in allVariables"
                            :value="value"
                            :key="'variable-' + index"
                          >
                            {{ value }}
                          </option>
                        </select>
                      </div>
                      </div>
                      <!-- 啟用根據父項隱藏 / 參照父項節點 -->
                      <div class="form-row px-4" v-if="formFieldVisible(formInput.template.form.type,'button','dropdown','dropdown2','dropdownMerge','checkbox','radio','button','datalist','buttonMultiple')">
                        <div class="form-group col-2">
                            <label class="form-check-label" for="enableHideBasedOnParent">{{ $t("__enableHideBasedOnParent") }}</label>
                            <input
                              type="checkbox"
                              class="form-control"
                              id="enableHideBasedOnParent"
                              v-model="formInput.template.form.enableHideBasedOnParent"
                              style="transform: scale(0.5); margin-right: 10px;"
                            />

                        </div>
                        <!-- Form / 設定父項節點 -->
                        <div class="form-group col-6" v-if="formInput.template.form.enableHideBasedOnParent==true">
                          <label>
                            {{ $t("__parentFieldReference") }}
                          </label>
                          <select class="form-control" v-model="formInput.template.form.parentFieldReference">
                            <option value selected>
                              {{ $t("__NextNodeoptional") }}
                            </option>
                            <option :value="node.nodeId" v-for="(node, index) in allNodes" :key="index">
                              {{ node.title.substr(0, 25) }} {{ node.subtitle }}
                            </option>
                          </select>

                          <label tooltip="遇到特定值才開啟隱藏">
                            {{ $t("__parentFieldReferenceSPValue") }}
                          </label>
                          <input type="text" class="form-control" v-model="formInput.template.form.parentFieldSPValue"  />
                        </div>
                      </div>

                      <!-- 標題類型為不同形式 有下拉選單可以選擇h1到h6 -->
                      <div v-if="formFieldVisible(formInput.template.form.type,'caption')">
                        <div class="form-row px-4">
                            <!-- Form / key -->
                            <div
                              class="form-group col-5"
                            >
                              <label>
                                type</label>
                              <select class="form-control" v-model="formInput.template.form.captionLevel">
                                  <option value selected>
                                    {{ $t("__UserTypeSelectOptional") }}
                                  </option>
                                  <option value="h1">h1</option>
                                  <option value="h2">h2</option>
                                  <option value="h3">h3</option>
                                  <option value="h4">h4</option>
                                  <option value="h4">h4</option>
                                  <option value="h6">h6</option>
                              </select>
                            </div>
                          </div>
                      </div>
                    </div>
                    <!-- Form / DataSource -->
                    <div  v-if="formInput.template.form.enable== true && formFieldVisible(formInput.template.form.type,'button','dropdown','dropdown2','dropdownMerge','text','date','hidden')"
                      class="border p-2 mb-2"
                    >
                      <h5>資料來源</h5>
                      <div class="row">
                        <div class="col ">


                        </div>
                        <div class="col "></div>

                      </div>
                      <div class="row">
                        <div class="col ">

                          <div class="form-row px-4" v-if="formFieldVisible(formInput.template.form.type,'button','buttonMultiple','dropdown','dropdown2','dropdownMerge','checkbox','datalist','radio','submit')">

                            <!-- LINE user -->
                            <div
                              class="form-group col-6"
                            >
                              <label>使用LINE USER 選取目前使用者</label>
                              <input
                                type="checkbox"
                                class="form-control"
                                v-model="formInput.template.form.enableLineUserCheck"
                                style="transform: scale(0.5);"
                              />
                            </div>
                          </div>

                        </div>
                        <div class="col "></div>
                      </div>
                      <!--增加自定義選單-->
                      <div class="row">
                        <div class="col px-10">

                          <!-- Form / 增加自定義選單 -->
                          <div class="form-row px-4">
                            <!-- 啟用自定義新增選項 -->
                            <div class="form-group col-3">
                              <label class="form-check-label" for="enableCustomOptions">{{ $t("__enableCustomOptions") }}</label>
                              <input
                                type="checkbox"
                                class="form-control"
                                id="enableCustomOptions"
                                v-model="formInput.template.form.allowCustomOptions"
                                style="transform: scale(0.5);"
                              />
                            </div>
                          </div>
                          <!-- 自定義 Key & Value 選項表單 -->
                          <div class="row mb-2 align-items-center" v-if="formInput.template.form.allowCustomOptions == true">
                            <div class=" col-2 my-2">

                            </div>
                            <div class=" col-4 my-2">
                              <p class="col-9 my-2"><b>Key</b></p>
                            </div>
                            <p class="col-4 my-2"><b>Value</b></p>
                            <a
                              href="javascript:void(0)"
                              class="text-info m-1"
                              @click="addCustomOption()"
                            >
                              <i class="fas fa-plus text-info"></i>
                            </a>
                          </div>

                          <!-- 顯示已新增的自定義選項 -->
                          <div class="row mb-2" v-if="formInput.template.form.allowCustomOptions == true" v-for="(option, index) in formInput.template.form.customOptions" :key="index">
                            <!-- Key 欄位 -->
                            <div class=" col-2 my-2">

                            </div>
                            <div class=" col-4 my-2">
                              <p class="col-9" :id="'custom-key-' + index">{{ option.key }}</p>
                              <span :id="'custom-edit-key-' + index" @click="editCustomOption('key', index)">
                                <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
                              </span>
                              <input
                                type="text"
                                class="form-control col-9 element-hide"
                                :id="'custom-input-key-' + index"
                                v-model="option.key"
                                @keypress="confirmChangeCustomOption($event, 'key', index)"
                              />
                            </div>

                            <!-- Value 欄位 -->
                            <div class="row col-5 my-2">
                              <p class="col-9" :id="'custom-value-' + index">{{ option.value }}</p>
                              <span :id="'custom-edit-value-' + index" @click="editCustomOption('value', index)">
                                <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
                              </span>
                              <input
                                type="text"
                                class="form-control col-9 element-hide"
                                :id="'custom-input-value-' + index"
                                v-model="option.value"
                                @keypress="confirmChangeCustomOption($event, 'value', index)"
                              />
                            </div>

                            <!-- 刪除按鈕 -->
                            <div class="col-1 my-2 d-flex align-items-center">
                              <button class="btn btn-sm btn-outline-danger" @click="deleteCustomOption(index)">
                                <i class="fas fa-trash"></i>
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col "></div>
                        <div class="col "></div>
                      </div>
                    </div>
                    <!-- Form / Output -->
                    <div v-if="formInput.template.form.enable== true && formFieldVisible(formInput.template.form.type,'all')"
                      class="border p-2 mb-2"
                    >
                      <h5>輸出</h5>
                      <div class="container">
                        <div class="row">
                          <div class="col">
                            <label for="enableExportCheckbox" class="form-check-label">{{ $t("__IsExport") }}</label>
                          </div>
                          <div class="col">

                            <input type="checkbox" id="enableExportCheckbox"
                              v-model="formInput.template.form.enableExport"
                              class="form-control"
                              style="transform: scale(0.5); margin-right: 10px;">
                          </div>
                        </div>
                        <!-- 變數 -->
                        <div class="form-row px-4" v-if="formFieldVisible(formInput.template.form.type,'all')">

                          <!-- Form / 變數type -->
                          <div
                            class="form-group col-4"
                            v-if=" accountType == 'line' && formInput.template.form.type !== 'submit'"

                          >
                            <label><span class="text-danger">*</span>
                              {{ $t("__variable") }}</label>
                              <a
                                href="javascript:void(0)"
                                class="text-info m-1"
                                @click="showExistVariable('UserReply')"
                              >
                                <i class="fas fa-edit text-info"></i>
                              </a>
                            <select class="form-control" v-model="formInput.template.form.variable">
                                <option value disabled selected>
                                  {{ $t("__UserVariableSelectOptional") }}
                                </option>
                                <option
                                  v-for="(value, index) in allVariables"
                                  :value="value"
                                  :key="'variable-' + index"
                                >
                                  {{ value }}
                                </option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <label for="autoMaxPlus1Checkbox" class="form-check-label">{{ $t("__AutoMaxPlus1") }}</label>
                          </div>
                          <div class="col">

                            <input type="checkbox" id="autoMaxPlus1Checkbox"
                              v-model="formInput.template.form.autoMaxPlus1"
                              class="form-control"
                              style="transform: scale(0.5); margin-right: 10px;">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <label>{{ $t("__ExportType") }}</label>
                          </div>
                          <div class="col">
                            <select class="form-control" v-model="formInput.template.form.export.type">
                              <option value="">{{ $t("__pleaseEnter")}}</option>
                              <option value="string">string</option>
                              <option value="array">array</option>
                            </select>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>
          <!-- フッター -->
          <div class="modal-footer">
            <button
              id="addNode"
              type="button"
              class="btn btn-secondary"
              data-toggle="modal"
              style="width: 100%"
              data-translate="_confirm.node"
              @click="submitNode()"
            >
              {{ $t("__determine") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <create-variable-modal
      v-on:newVariable="newVariable"
    ></create-variable-modal>
    <exist-variable-modal
      v-bind:allVariables="variables"
      v-on:addVariable="addVariable"
      v-on:removeVariable="removeVariable"
    >
    </exist-variable-modal>
    <var-form-modal
      v-bind:allVariables="variables"
      v-bind:formObject="formObjectModel"
      v-on:saveForm="saveForm"
    >
    </var-form-modal>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { Utility } from "../../../utility";
import { apiFunction } from "../Axios/functions";
import { apiFunction as apiFunctionMain } from "../../../Axios/functions";
import { FbGenericTemplateSetting, VarFormSetting } from "../SettingPages/index";
import CreateVariableModal from "./CreateVariable.vue";
import ExistVariableModal from "./ExistVariable";
import VarFormModal from "./VarFormModal";

export default {
  components: {
    Datetime,
    ExistVariableModal,
    CreateVariableModal,
    VarFormModal,
    vueDropzone: vue2Dropzone,
    FbGenericTemplateSetting,
    VarFormSetting
  },
  props: {
    adminId: String,
    jwtToken: String,
    accountId: String,
    scenarioId: String,
    nodeData: Object,
    allNodes: Array,
    allTags: Array,
    allVariables: Array,
    allScenario: Array,
    allSegments: Array,
    allVarForms: Array,
    crosApiErrs: Array,
  },
  name: "create-edit-form-node-modal",

  data() {
    const uploadText =
      "<div><i class='fa fa-cloud-upload-alt pr-2' style='font-size: 3.5rem;'></i><h4>" +
      this.$t("__Clickordragtoupload") +
      "</h4></div>";

    return {
      customOption: {
        key: '',
        value: ''
      },
      liffKeys: [],  // To store the keys
      externals: [],
      accountType: "",
      token: null,
      isShow: false,
      flexMsgInvalid: null,
      variablesSelectMode: "",
      tagList: [],
      dtPickerType: "datetime-local",

      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 300,
        maxFilesize: 500,
        addRemoveLinks: true,
        uploadMultiple: true,
        maxFiles: 1,
        dictDefaultMessage: uploadText,
      },
      buttonStyleSelect: {
        text: "",
        align: "horizontal",
      },
      condInput: {
        config_url: null,
        options: [],
      },
      segmentInput: {
        options: [],
      },
      formInput: {
        bubbleSize: ["giga", "mega", "kilo", "micro", "nano"],
        flexSize: "",
        nodeType: "Message",
        nextnodeType: "nnode",
        nodeName: "",
        subtitle: "",
        boldText1: "",
        boldText2: "",
        urlTitle: "",
        urlPic: "",
        urlPicTransition: "",
        picRatioX: "",
        picRatioY: "",
        text: "",
        textMsg: {
          text: "",
          emojis: [],
        },
        quickReply: {
          items: [],
        },
        button: [],
        datetimepicker: {
          color: "#00b900",
          style: "primary",
          type: "button",
          nodetype: "",
          action: {
            type: "datetimepicker",
            label: "",
            data: "",
            mode: "datetime",
            initial: "",
            max: "",
            min: "",
          },
          tag: {
            color: "",
            name: "",
          },
          tagValue: "",
          userVars: [],
          varName: "",
          nextNode: "",
          nextScenario: "",
        },
        conditionConfig: {
          options: [],
        },
        tags: [],
        packageId: null,
        stickerId: null,
        flexMsgTitle: "",
        flexMessage: null,
        flexMsgConfigs: [],
        richMsg: {
          title: "",
          layout: "A",
          baseUrl: "",
          areaOptions: [],
        },
        reservations: [],
        originalImageUrl: "",
        originalVideoUrl: "",
        replyMsg: {},
        userAction: {
          userReply: {
            variables: [],
            selectCompare: "",
            lensCompare: [
              {
                value: "<",
                label: "lessThan",
              },
              {
                value: ">",
                label: "moreThan",
              },
              {
                value: "=",
                label: "equal",
              },
            ],
            validate: {
              inputList: [
                {
                  type: null,
                  value: "",
                },
              ],
              success: {
                nextNodeId: "",
                scenarioId: "",
              },
              failed: {
                nextNodeId: "",
                scenarioId: "",
              },
            },
            encryption: false,
          },

          emptyVariable: {
            variables: [],
          },
        },
        constant: {
          ecsite: false,
          chichatcatBox: false,
        },
        externalApi: {
          cros: {
            appId: "",
            behavior: "",
            enable: false,
            responseSetting: {
              success: {
                nextNodeId: "",
                scenarioId: "",
              },
              failed: [],
            },
            htmlSetting: {
              isRequired: false,
              type: "",
              key: "",
              value: "",
              enableFilterNode: false,
              filterNodeId: "",
            },
            // others: {
            //   orderIdReplace: false,
            // },
          },
        },
        apps: [
        {
          enable: false,
          appId: "",
          behavior: "",
        }
        ],
        fbTag: "NORMAL",
        fbGenericElements: [],
        varForms: [],
        datetimeTrigger:{
          enable: false,
          startTime: "",
          endTime: "",
        },
        liff:[],
        template:{
          form: {
            type: 'input',
            title: "",
            variable: "",
            order:{
              enable: false,
              type: "",
              field: "",
            },
            allowCustomOptions:false,
            customOptions: [],
            additionalEntries: [],
            enableRender: true,  // 預設為 true
            export:{
              type:"string", // 預設為 string, string|array
            }
          }
        },
      },
      urldata: null,
      nextNodeSelection: [],
      nextAndSameNodeSelection: [],
      jumpType: "",
      nextNode: "",
      nextScenario: "",
      isInvalidScheduleTime: false,
      variables: [],
      isInvalidScheduleTime: false,
      userReplyValidateNextType: {
        success: "",
        failed: "",
      },
      userReplyValidateNodes: {
        success: [],
        failed: [],
      },
      formObjectModel: null
    };
  },
  watch: {
    formInput: {
      handler: function (newVal) {
        if (newVal.urlPic != "") {
          var img = new Image();
          img.onload = () => {
            this.formInput.picRatioX = img.width;
            this.formInput.picRatioY = img.height;
          };
          img.src = newVal.urlPic;
        }
        this.isInvalidScheduleTime =
          newVal.scheduletime != null && newVal.scheduletime < 0;
        // Check Flex message format
        if (newVal.flexMessage) {
          try {
            var json = JSON.parse(newVal.flexMessage);
            var err = Utility.checkFlexMsgJson(json);
            if (err) {
              this.flexMsgInvalid = err;
            } else {
              this.flexMsgInvalid = null;
            }
          } catch (e) {
            this.flexMsgInvalid = "invalid json";
          }
        }
        this.isInvalidScheduleTime =
          newVal.scheduletime != null && newVal.scheduletime < 0;
      },
      deep: true,
    },
  },
  mounted() {
    this.$watch(
      "modalData",
      (modalData) => {
        this.loadData();
      },
      { immediate: true }
    );
    this.$nextTick(() => {
      var fileUrl = "";
      var dropzone = null;
      if ( this.formInput.urlPic != "" ) {
        fileUrl = this.formInput.urlPic;
        dropzone = this.$refs.myVueDropzone;
      } else if ( this.formInput.richMsg.baseUrl != "" ) {
        // fileUrl = this.formInput.richMsg.baseUrl + "/1040.jpg";
        // dropzone = this.$refs.myVueDropzone;
      } else if ( this.formInput.originalImageUrl && this.accountType === 'line' ) {
        fileUrl = this.formInput.originalImageUrl;
        dropzone = this.$refs.OriginalVueDropzone;
      }

      if (fileUrl != "") {
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', fileUrl, true);
        xhr.onreadystatechange = function(){
          if ( xhr.readyState == 4 ) {
            if ( xhr.status == 200 ) {
              var fileSize = xhr.getResponseHeader('Content-Length');
              var fileType = xhr.getResponseHeader('Content-Type');
              var tmpArr = fileUrl.split("/");
              var fileName = tmpArr[tmpArr.length - 1];
              var imgFile = { size: fileSize, name: fileName, type: fileType };
              dropzone.manuallyAddFile(imgFile, fileUrl);
            }
          }
        };
        xhr.send();
      }
    });
  },
  methods: {
    // 新增 Parameter 和 Variable 配對項目
    addAdditionalEntry() {
      if (!this.formInput.template || !this.formInput.template.form) {
        // Initialize `form` and `additionalEntries` if they don't exist
        this.$set(this.formInput.template, 'form', { additionalEntries: [] });
      } else if (!this.formInput.template.form.additionalEntries) {
        // Initialize `additionalEntries` if it doesn't exist
        this.$set(this.formInput.template.form, 'additionalEntries', []);
      }
      this.formInput.template.form.additionalEntries.push({
        parameter: '',
        variable: ''
      });
    },
    // 刪除指定索引的 Parameter 和 Variable 配對項目
    deleteAdditionalEntry(index) {
      this.formInput.template.form.additionalEntries.splice(index, 1);
    },
    // 新增自定義選項
    addCustomOption() {
      if (!this.formInput.template.form.customOptions) {
        this.formInput.template.form.customOptions = [];
      }
      this.formInput.template.form.customOptions.push({ key: '', value: '' });
      this.$forceUpdate();
    },

    // 編輯自定義選項
    editCustomOption(field, index) {
      $(`#custom-${field}-` + index).hide();
      $(`#custom-input-${field}-` + index).show();
      $(`#custom-edit-${field}-` + index).hide();
    },

    // 確認更改自定義選項
    confirmChangeCustomOption($event, field, index) {
      if ($event.which === 13) {
        $(`#custom-${field}-` + index).show();
        $(`#custom-input-${field}-` + index).hide();
        $(`#custom-edit-${field}-` + index).show();
        this.$forceUpdate();
      }
    },

    // 刪除自定義選項
    deleteCustomOption(index) {
      this.formInput.template.form.customOptions.splice(index, 1);
      this.$forceUpdate();
    },
    // Prepare page content
    loadData() {
      // 檢查 formInput.template.form 是否存在 additionalEntries，若不存在則初始化為空陣列
      if (!this.formInput.template.form.additionalEntries) {
        this.$set(this.formInput.template.form, 'additionalEntries', []);
      }

      // 確保 customOptions 也初始化
      if (!this.formInput.template.form.customOptions) {
        this.$set(this.formInput.template.form, 'customOptions', []);
      }
      const tagModalElement = document.querySelector('.tag-modal');
      if (tagModalElement) {
          tagModalElement.style.border = '1px solid blue';
      }

      this.accountType = this.getAccountType();
      // Count current node and get Next level
      this.getNextLevelNodes(this.nodeData.totalNode);
      this.getNextAndSameLevelNodes(this.nodeData.totalNode);
      if (!localStorage.imgur) {
        // Get new imgur token
        this.getImgurAccessToken();
      }
      if (this.nodeData.type == "condition") {
        this.formInput.nodeType = "Condition";
      } else {
        this.formInput.nodeType = "Message";
      }
      let nodeData = Utility.deepCopy(this.nodeData);
      this.formInput.nodeName = this.nodeData.nodeName;
      this.formInput.subtitle = this.nodeData.subtitle;
      this.formInput.boldText1 = this.nodeData.boldText1;
      this.formInput.boldText2 = this.nodeData.boldText2;
      this.formInput.urlTitle = this.nodeData.urlTitle;
      this.formInput.urlPic = this.nodeData.urlPic;
      this.formInput.urlPicTransition = this.nodeData.urlPicTransition;
      this.formInput.picRatioX = this.nodeData.picRatioX;
      this.formInput.picRatioY = this.nodeData.picRatioY;
      this.formInput.text = this.nodeData.text;
      this.formInput.textMsg = Utility.deepCopy(this.nodeData.textMsg);
      this.formInput.quickReply = Utility.deepCopy(this.nodeData.quickReply);
      this.formInput.flexMsgTitle = this.nodeData.flexMsgTitle;
      this.formInput.originalImageUrl = this.nodeData.originalImageUrl;
      this.formInput.originalVideoUrl = this.nodeData.originalVideoUrl;
      this.formInput.replyMsg = this.nodeData.replyMsg;
      this.formInput.flexSize = this.nodeData.flexSize;
      this.formInput.bubbleSize = this.nodeData.bubbleSize;
      this.formInput.datetimeTrigger = this.nodeData.datetimeTrigger;
      // 根據 nodeId 尋找對應的 template.form
      let matchingNode = this.nodeData.totalNode.find(node => node.nodeId === this.nodeData.nodeId);

      if (matchingNode && matchingNode.template && matchingNode.template.form) {
        // 當找到對應的 nodeId 並且 template 和 template.form 存在時
        this.formInput.template = matchingNode.template;  // 複製整個 template
        this.formInput.template.form = matchingNode.template.form;  // 只複製 form
        console.log('matchingNode.template.form', matchingNode.template.form);
      } else {
        // 如果沒有找到對應的 template 或 form，不加載，初始化為空物件
        this.formInput.template = {};
        this.formInput.template.form = {};
        this.formInput.template.form = {
          type: "",  // 初始化 type 為空字串，確保選項正常運作
          title: "",
          variable: "",
        };
      }

      // Check field exist
      if(!this.formInput.template.form.export){
        this.formInput.template.form.export = {
          type: "string"
        }
      }

      // Button
      if (this.nodeData.buttonArea.length > 0) {
        let buttonArr = Utility.deepCopy(this.nodeData.buttonArea);
        let buttonForm = {};
        $.each(buttonArr, function (key, button) {
          let type = "";
          let nextNode = "";
          let nodetype = "";

          if (button.action.type == "uri") {
            type = button.action.uri;
            nodetype = "uri";
          } else if (button.action.type == "postback") {
            nextNode = button.action.data.split("&")[1].substr(11);
            nodetype = "nnode";
            if (nextNode == "''" || nextNode == "") {
              nextNode = button.action.data.split("&")[0].substr(11);
              nodetype = "snode";
            }
          } else if (button.action.type == "") {
            nextNode = button.action.data;
          }

          buttonForm = {
            action: {
              data: nextNode,
              label: button.action.label,
              type: type,
              nodetype: nodetype,
            },
            color: button.color,
            style: button.style,
            tag: {
              color: button.tag ? button.tag.color : "",
              name: button.tag ? button.tag.name : "",
            },
            tagValue: button.tagValue ? button.tagValue : "",
            userVars: button.userVars ? button.userVars : [],
            type: "button",
            saveTimestampCheck: button.saveTimestampCheck,
            saveTimestampValue: button.saveTimestampValue
          };
          buttonArr.splice(key, 1, buttonForm);
        });
        this.formInput.button = buttonArr;
      }
      this.buttonStyleSelect.align = this.nodeData.buttonStyleSelect;

      this.formInput.tags = Utility.deepCopy(this.nodeData.tags);
      if (this.nodeData.sticker !== null) {
        this.formInput.packageId = this.nodeData.sticker.packageId;
        this.formInput.stickerId = this.nodeData.sticker.stickerId;
      } else {
        this.formInput.packageId = null;
        this.formInput.stickerId = null;
      }
      this.formInput.flexMessage = this.nodeData.flexMessage;
      if (this.formInput.flexMessage !== null) {
        this.formInput.flexMessage = JSON.stringify(this.formInput.flexMessage);
      }
      this.formInput.flexMsgConfigs = this.nodeData.flexMsgConfigs;
      this.nextScenario = this.nodeData.nextScenario;
      this.nextNode = this.nodeData.nextNode;
      if (this.nextScenario != "") {
        this.jumpType = "scenario";
      } else if (this.nextNode != "") {
        this.jumpType = "node";
      } else {
        this.jumpType = "";
      }
      // Use config_url to determine which condition type is using
      if (
        this.nodeData.condition_config != null &&
        this.nodeData.condition_config.config_url != null &&
        this.nodeData.condition_config.config_url != "bysegment" &&
        this.nodeData.condition_config.config_url != "bytag"
      ) {
        this.condInput = this.nodeData.condition_config;
      } else if (
        this.nodeData.condition_config != null &&
        this.nodeData.condition_config.options != null &&
        this.nodeData.condition_config.config_url === "bysegment"
      ) {
        this.segmentInput = this.nodeData.condition_config;
      } else if (
        this.nodeData.condition_config != null &&
        this.nodeData.condition_config.options != null &&
        this.nodeData.condition_config.config_url === "bytag"
      ) {
        this.formInput.conditionConfig = nodeData.condition_config;
        this.formInput.conditionConfig.options.forEach((item) => {
          item.multi_condition.forEach((subItem) => {
            if (subItem.expression == "date_range") {
              if (subItem.range.start !== "") {
                subItem.range.start = this.$dateTime.toIsoFormat(
                  subItem.range.start + " UTC"
                );
              }
              if (subItem.range.end !== "") {
                subItem.range.end = this.$dateTime.toIsoFormat(
                  subItem.range.end + " UTC"
                );
              }
            }
          });
        });
      }

      // reset tag list
      this.tagList.splice(0);
      this.allTags.forEach((tag) => {
        this.tagList.push({
          name: tag.name,
          color: tag.color,
        });
      });
      this.variables = this.allVariables;
      // node constant
      this.formInput.constant = this.nodeData.constant;
      // External Api Enable
      let externalApi = Utility.deepCopy(this.nodeData.externalApi);
      // since responseSetting is new feature, check if it exist.
      if (!externalApi.cros.responseSetting) {
        externalApi.cros.responseSetting = {
          success: {
            nextNodeId: "",
            scenarioId: "",
          },
          failed: {},
        };
      }
      if (!externalApi.cros.htmlSetting) {
        externalApi.cros.htmlSetting = {
          type: "",
          key: "",
          value: "",
          enableFilterNode: false,
          filterNodeId: "",
        };
      }
      let externalApiFailedArr = [];
      for (var key in externalApi.cros.responseSetting.failed) {
        let item = externalApi.cros.responseSetting.failed[key];
        externalApiFailedArr.push({
          error: key,
          nextNodeId: item.nextNodeId,
          scenarioId: item.scenarioId,
        });
      }
      externalApi.cros.responseSetting.failed = externalApiFailedArr;
      if(externalApi.cros.responseSetting.failed.length === 0){
        let form = {
          error: "",
          nextNodeId: "",
          scenarioId: "",
        };
        externalApiFailedArr.push(form);
      }

      // Check if others node exist.
      // if (!externalApi.cros.others) {
      //   externalApi.cros.others = {
      //     orderIdReplace: false,
      //   };
      // }
      this.formInput.externalApi = externalApi;
      // Load apps data
      if (this.nodeData.apps && Array.isArray(this.nodeData.apps)) {
          // 如果 nodeData.apps 存在且為陣列，則進行深度拷貝
          this.formInput.apps = Utility.deepCopy(this.nodeData.apps);
      } else {
          // 如果 nodeData.apps 不存在或不是陣列，初始化為一個包含一個空的 app 對象的陣列
          this.formInput.apps = [
              {
                  appId: "",
                  enable: false,
                  behavior: "",
              }
          ];
      }

      //# getAccount 為了取得連攜資料
      console.log('externals');
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId
      };
      apiFunctionMain.getAccount(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
          });
        } else {
          let account = data.data.Result.accounts.find(a=>a.accountId == formData.accountId);
          if(account){
            this.account = account;

            if(this.account.setting.cros.payloads.length >=1){
              this.externals = this.account.setting.cros.payloads;
              this.externals = this.externals.filter(e=>e.app_id != null && e.app_id != '');
            }
          }

        }
      });

      // varForms
      this.formInput.varForms = nodeData.varForms;
      $("#nodeModal").modal("show");
    },
    onTypeChange(event) {
      this.$set(this.formInput.template.form, 'type', event.target.value);
      this.formInput.template.form.type = event.target.value;
      this.$forceUpdate(); // 強制刷新
      console.log('Type immediately updated to:', event.target.value);
      // 你可以在這裡加入任何其他需要的處理邏輯
    },
    handleInputChange(event) {
      this.formInput.template.form.defaultValue = event.target.checked;
      console.log('Checkbox value changed to:', this.formInput.template.form.defaultValue);
    },
    updateFormValue(name, val) {
      if (this.formInput[name]) {
        this.formInput[name] = val;
      }
    },
    onmessage(event) {
      if (event == "Message") {
        this.$nextTick(() => {
          if(this.accountType === "ig" || this.accountType === "fb"){
            $("#v-pills-image-tab").click();
            return;
          };
          // 最上段の設定項目（タイトル）を選択した状態にする
          $("#v-pills-boldText1-tab").click();
        });
      } else {
        this.$nextTick(() => {
          $("#v-pills-conditions-tab").removeClass("active");
          $("#v-pills-segment-tab").removeClass("active");
          // 最上段の設定項目（URL）を選択した状態にする
          $("#v-pills-url-tab").click();
        });
      }
    },
    onchanged(event, index) {
      if (event.target.id != "") {
        if (event.target.id[0] == "c") {
          //for condition
          if (event.target.id == "condsnode") {
            this.condInput.options[index].nextnodeType = "snode";
            this.condInput.options[index].nextNodeId = "";
          } else {
            this.condInput.options[index].nextnodeType = "nnode";
            this.condInput.options[index].scenario_id = "";
          }
        } else if (
          event.target.id == "segmentnnode" ||
          event.target.id == "segmentsnode"
        ) {
          //for segment condition
          if (event.target.id == "segmentsnode") {
            this.segmentInput.options[index].nextnodeType = "snode";
            this.segmentInput.options[index].nextNodeId = "";
          } else {
            this.segmentInput.options[index].nextnodeType = "nnode";
            this.segmentInput.options[index].scenario_id = "";
          }
        } else if (
          event.target.id == "tagnnode" ||
          event.target.id == "tagsnode"
        ) {
          //for tag condition
          if (event.target.id == "tagsnode") {
            this.formInput.conditionConfig.options[index].nextnodeType =
              "snode";
            this.formInput.conditionConfig.options[index].nextNodeId = "";
          } else {
            this.formInput.conditionConfig.options[index].nextnodeType =
              "nnode";
            this.formInput.conditionConfig.options[index].scenario_id = "";
          }
        } else if (event.target.id == "nnode" || event.target.id == "snode") {
          //for button scenarion/node selection
          this.formInput.button[index].action.nodetype = event.target.id;
          this.formInput.button[index].action.type = "";
        } else if (event.target.id == "datesnode") {
          this.formInput.datetimepicker.nodetype = event.target.id;
          this.formInput.datetimepicker.nextNode = "";
        } else if (event.target.id == "datennode") {
          this.formInput.datetimepicker.nodetype = event.target.id;
          this.formInput.datetimepicker.nextScenario = "";
        } else if (event.target.id.indexOf("uri") != -1) {
          //for button url input
          this.formInput.button[index].action.nodetype = "uri";
        } else if (event.target.id == "btntag") {
          //for button tag selection, reset tag value
          this.formInput.button[index].tagValue = "";
        } else if (event.target.id == "datetimepicker") {
          this.formInput.datetimepicker.tagValue = "";
        } else if (event.target.id == "flexcfgtag") {
          //for flex config tag selection, reset tag value
          this.formInput.flexMsgConfigs[index].tagValue = "";
        } else if (event.target.id === "jumpscenario") {
          //for jump next Scenario
          this.jumpType = "scenario";
          this.nextNode = "";
        } else if (event.target.id === "jumpnode") {
          //for jump next Node
          this.jumpType = "node";
          this.nextScenario = "";
        }
      }
    },

    onNextScenarioNodeChanged(event) {
      if (event.target.id != "") {
        switch (event.target.id) {
          case "userReplyValidateSuccessScenario":
            this.userReplyValidateNextType.success = "scenario";
            this.formInput.userAction.userReply.validate.success.nextNodeId =
              "";
            break;
          case "userReplyValidateSuccessNode":
            this.userReplyValidateNextType.success = "node";
            this.formInput.userAction.userReply.validate.success.scenarioId =
              "";
            break;
          case "userReplyValidateFailedScenario":
            this.userReplyValidateNextType.failed = "scenario";
            this.formInput.userAction.userReply.validate.failed.nextNodeId = "";
            break;
          case "userReplyValidateFailedNode":
            this.userReplyValidateNextType.failed = "node";
            this.formInput.userAction.userReply.validate.failed.scenarioId = "";
            break;
        }
      }
    },

    onCondSourceChanged(event, index, subIndex) {
      let newSource = event.target.value;
      this.formInput.conditionConfig.options[index].multi_condition[
        subIndex
      ].value = "";
      this.formInput.conditionConfig.options[index].multi_condition[
        subIndex
      ].tag = "";
      this.formInput.conditionConfig.options[index].multi_condition[
        subIndex
      ].uservariable = {
        name: "",
      };
      this.formInput.conditionConfig.options[index].multi_condition[
        subIndex
      ].range = {
        start: "",
        end: "",
      };
      let newExpression = "";
      if (newSource === "followtime") {
        newExpression = "date_range";
      } else if (newSource === "cvkey") {
        newExpression = "has_value";
        this.formInput.conditionConfig.options[index].multi_condition[
          subIndex
        ].value = "orderId";
      } else {
        newExpression = "exactly_match";
      }
      this.formInput.conditionConfig.options[index].multi_condition[
        subIndex
      ].expression = newExpression;
    },
    onCondTagChanged(index, subIndex) {
      this.formInput.conditionConfig.options[index].multi_condition[
        subIndex
      ].value = "";
    },
    // Get account type from _token
    getAccountType() {
      let decrypt = this.$aes.decrypt(localStorage._token).split(",");
      let accountType = decrypt[3];
      return accountType;
    },
    // Get account token from _token
    accountToken() {
      let decrypt = this.$aes.decrypt(localStorage._token).split(",");
      let accountToken = decrypt[4];
      return accountToken;
    },
    // Get imgur access token
    async getImgurAccessToken() {
      let client_id = "208d8a2829b83a4";
      let secret = "8acb5af7e2368314866e1c7300471509df22a712";

      let formData = new FormData();
      formData.append(
        "refresh_token",
        "ed342a5803077918e62e009d0964dd470fec010a"
      ); //required
      formData.append("client_id", client_id);
      formData.append("client_secret", secret);
      formData.append("grant_type", "refresh_token");

      await this.axios({
        method: "POST",
        url: "https://api.imgur.com/oauth2/token",
        data: formData,
      })
        .then((res) => {
          localStorage.imgur = res.data.access_token;
          // return (this.token = res.data.access_token);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // Display exist tag modal
    showExistTag() {
      if (this.nodeData.nodeId != "") {
        // nodeIdが空文字では無い（保存済みノード）の場合、現在のノード編集内容を一旦保存する
        this.submitNode();
      } else {
        // nodeIdが空文字（未保存ノード）の場合、一旦保存せず閉じる（ラベルの反映ができないので）
        $("#nodeModal").modal("hide");
      }
      $("#tag").modal("show");
    },
    // Display exist variable modal
    showExistVariable(mode) {
      this.variablesSelectMode = mode;
      $("#variable").modal("show");
    },
    // Get next level nodes
    getNextLevelNodes(totalNode) {
      let nodes = [];
      totalNode.forEach((node) => {
        if (Number(node.level) == Number(this.nodeData.level) + 1) {
          nodes.push(node);
        }
      });
      return (this.nextNodeSelection = nodes);
    },
    // Get next level nodes
    getNextAndSameLevelNodes(totalNode) {
      let nodes = [];
      totalNode.forEach((node) => {
        if (
          Number(node.level) == Number(this.nodeData.level) + 1 ||
          (Number(node.level) == Number(this.nodeData.level) &&
            node.nodeId != this.nodeData.nodeId)
        ) {
          nodes.push(node);
        }
      });
      return (this.nextAndSameNodeSelection = nodes);
    },
    // Flex message footer button
    addNewButton() {
      let buttonForm = {
        action: {
          data: "",
          label: "",
          type: "",
          nodetype: "uri",
        },
        color: "#00b900",
        style: "primary",
        type: "button",
        tag: {
          color: "",
          name: "",
        },
        tagValue: "",
        userVars: [],
        saveTimestampCheck: false,
        saveTimestampValue: ""
      };
      this.formInput.button.push(buttonForm);
    },
    // Delete target flex message footer button
    deleteButton(index) {
      this.formInput.button.splice(index, 1);
    },

    deleteButtonRegex(index) {
      this.formInput.userAction.userReply.validate.inputList.splice(index, 1);
    },
    // Flex message footer button


    addNewApiResFailedSetting() {
      let form = {
        error: "",
        nextNodeId: "",
        scenarioId: "",
      };
      this.formInput.externalApi.cros.responseSetting.failed.push(form);
    },
    deleteApiResFailedSetting(index) {
      this.formInput.externalApi.cros.responseSetting.failed.splice(index, 1);
      this.userReplyValidateNodes.failed.splice(index, 1);
    },
    // Validate Url for node button
    validURL(str) {
      var res = str.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      return res !== null;
    },
    // Sort nodes
    getNewSort(level) {
      let nodes = this.nodeData.totalNode;
      let sort = 1;

      nodes.forEach((node) => {
        if (node.sort <= sort) {
          sort = sort + 1;
        }
      });

      return sort;
    },
    // Delete tag when clicking tag on top of modal
    deleteTag(targetTag) {
      if (
        targetTag.name.length >= 3 &&
        targetTag.name.substring(0, 2) == "Lv" &&
        targetTag.color == "bg-danger"
      ) {
        return this.$fire({
          title: this.$t("__failedtodelete"),
          text: this.$t("__UnabletodeleteLevellabel"),
          type: "error",
        });
      }

      this.$fire({
        title: this.$t("__Areyousureyouwanttodeletethelabel"),
        text: this.$t("__Thesystemwillnotbeabletoretrieveyourdata"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("__YesIwanttodelete"),
        cancelButtonText: this.$t("__cancel"),
      }).then((result) => {
        if (result.value) {
          let deletingIndex = -1;
          this.formInput.tags.forEach((tag, index) => {
            if (tag.name == targetTag.name && tag.color == targetTag.color) {
              deletingIndex = index;
            }
          });
          this.formInput.tags.splice(deletingIndex, 1);

          if (deletingIndex == -1) {
            return this.$fire({
              title: this.$t("__failedtodelete"),
              text: this.$t("__oopsSomethingwentwrong"),
              type: "error",
            });
          }
        }
      });
    },
    // Create & edit node
    submitNode() {
      const NODE_NAME_SIZE_MAX = 60;
      let self = this;
      let flextemplate = {};
      let content = {};
      let nodeName = "";
      let nodeType = "";
      let error = 0;
      let spaceCheckError = [];
      let buttonSpaceCheck = 0;

      // Default level tag
      let nodeTag = [{ color: "bg-danger", name: "Lv" + this.nodeData.level }];
      if (this.formInput.flexSize == "") {
        this.formInput.flexSize = "mega";
      }
      let flexTemp = {
        type: "bubble",
        size: this.formInput.flexSize,
      };
      // === Bold text 1(Title)
      if (this.formInput.boldText1 != "") {
        if (!this.formInput.boldText1.trim() == "") {
          content = {
            size: "xl",
            text: this.formInput.boldText1,
            type: "text",
            weight: "bold",
            wrap: true,
          };
          nodeType = "input";
          nodeName = this.formInput.boldText1.substr(0, NODE_NAME_SIZE_MAX);
          if (!("body" in flexTemp)) {
            flexTemp.body = { contents: [] };
          } else {
            if (!("contents" in flexTemp.body)) {
              flexTemp.body = { contents: [] };
            }
          }
          flexTemp.body.contents.push(content);
        } else {
          error = 1;
          spaceCheckError.push(this.$t("__Titlebold"));
        }
      }
      // === Bold text 2(Sub Title)
      if (this.formInput.boldText2 != "") {
        if (!this.formInput.boldText2.trim() == "") {
          content = {
            size: "lg",
            text: this.formInput.boldText2,
            type: "text",
            weight: "bold",
            wrap: true,
          };
          nodeType = "input";
          if (!nodeName) {
            nodeName = this.formInput.boldText2.substr(0, NODE_NAME_SIZE_MAX);
          }
          if (!("body" in flexTemp)) {
            flexTemp.body = { contents: [] };
          } else {
            if (!("contents" in flexTemp.body)) {
              flexTemp.body = { contents: [] };
            }
          }
          flexTemp.body.contents.push(content);
        } else {
          error = 1;
          spaceCheckError.push(this.$t("__Subtitlebold"));
        }
      }



      if ("body" in flexTemp) {
        if ("contents" in flexTemp.body) {
          flexTemp.body.layout = "vertical";
          flexTemp.body.type = "box";
        }
      }

      let nodeId = this.nodeData.nodeId;
      // If new node, create id
      if (nodeId === "") {
        nodeId = Utility.generateNewId(
          this.$dateTime.getDateTime(),
          this.nodeData.totalNode.length
        );
      }
      // === Button
      if (this.formInput.button.length > 0) {
        console.log(this.formInput.button)
        // change uri form
        if (!nodeName) {
          nodeName = this.$t("__Button");
        }
        let scenarioId = this.scenarioId;
        let buttonInputs = [];

        $.each(this.formInput.button, function (key, button) {
          if (button.action.label == null || button.action.label == "") {
            error = 1;
          } else if (button.action.label.trim() == "") {
            button.action.label = "";
            error = 1;
            buttonSpaceCheck = 1;
          }
          var newButton = {
            type: "button",
            color: button.color,
            style: button.style,
            tag: button.tag,
            tagValue: button.tagValue,
            userVars: button.userVars,
            height: "sm",
            action: {
              type: "uri",
              label: button.action.label,
              uri: "",
            },
            saveTimestampCheck: button.saveTimestampCheck,
            saveTimestampValue: button.saveTimestampValue
          };
          // 若type欄位不為空 則為url連結
          //nodetype: uri / snode /nnode
          if (button.action.nodetype == "uri") {
            // url 驗證
            if (button.action.type.indexOf("http") === 0) {
              newButton.action.uri = button.action.type;
            } else {
              error = 1;
            }
          }
          // 設定post back data
          else if (
            button.action.nodetype == "nnode" ||
            button.action.nodetype == "snode"
          ) {
            // Prepare post data
            let scenarioIdValue = "";
            let nextNodeIdValue = "";
            if (button.action.nodetype == "nnode") {
              scenarioIdValue = scenarioId;
              nextNodeIdValue = button.action.data;
            } else if (button.action.nodetype == "snode") {
              scenarioIdValue = button.action.data;
              nextNodeIdValue = "''";
            }
            let postData = {
              scenarioId: scenarioIdValue,
              nextNodeId: nextNodeIdValue,
              nodeId: nodeId,
              tagColor: button.tag.color,
              tagName: button.tag.name,
              tagValue: button.tagValue,
              userVars: "",
              saveTimestampCheck: button.saveTimestampCheck,
              saveTimestampValue: button.saveTimestampValue
            };
            let userVarsDict = {};
            button.userVars.forEach((varItem) => {
              userVarsDict[varItem.variable] = varItem.value;
            });
            let userVarsString = JSON.stringify(userVarsDict);
            postData.userVars = userVarsString;
            let postDataString = jQuery.param(postData);
            // Update action object with postdata.
            newButton.action = {
              type: "postback",
              label: button.action.label,
              data: postDataString,
            };
          } else {
            error = 1;
          }
          buttonInputs.push(newButton);
        });

        nodeType = "input";
        // Update to button
        this.formInput.button = buttonInputs;
        flexTemp.footer = {
          spacing: "sm",
          contents: this.formInput.button,
          layout: this.buttonStyleSelect.align, //select
          type: "box",
        };
      }

      let nextScenario = this.jumpType === "scenario" ? this.nextScenario : "";
      let nextNode = this.jumpType === "node" ? this.nextNode : "";

      if (buttonSpaceCheck == 1) {
        spaceCheckError.push(this.$t("__Buttontext"));
      }

      if (spaceCheckError.length > 0) {
        // スペース混入チェックエラー
        this.$alert(spaceCheckError.join(",") + this.$t("__characterinput"));
      }

      nextNode = this.nextNode;
      flextemplate = flexTemp;

      // === Flex Message
      // If flex message, replace current template
      if (this.formInput.flexMessage || this.formInput.flexMsgTitle) {
        nodeType = "flex";
        if (this.formInput.flexMsgTitle) {
          nodeName = this.formInput.flexMsgTitle.substr(0, NODE_NAME_SIZE_MAX);
        } else {
          nodeName = this.$t("__flexMessage");
        }
        if (this.formInput.flexMessage) {
          flextemplate = JSON.parse(this.formInput.flexMessage);
        } else {
          flextemplate = null;
        }
        if (!this.formInput.flexMessage && this.formInput.flexMsgTitle) {
          error = 1;
        }
      }
      let flexConfig = this.formInput.flexMsgConfigs;
      // If tag is chosen, append tag
      if (this.formInput.tags.length > 0) {
        nodeTag = [];
        this.formInput.tags.forEach((tag) => {
          nodeTag.push(tag);
        });
      }



      // == Conditions
      let condition_config = {};
      if (
        this.condInput != null &&
        this.condInput.config_url != null &&
        this.condInput.config_url != "" &&
        this.condInput.config_url != "bysegment" &&
        this.condInput.config_url != "bytag"
      ) {
        condition_config = this.condInput;
        if (nodeName == "") {
          nodeName = this.$t("__nodeNameConditionUrl");
        }
      } else if (
        this.segmentInput != null &&
        this.segmentInput.options != null &&
        this.segmentInput.options.length > 0
      ) {
        condition_config = this.segmentInput;
        condition_config.config_url = "bysegment";
        if (nodeName == "") {
          nodeName = this.$t("__nodeNameConditionSegment");
        }
      } else if (
        this.formInput.conditionConfig != null &&
        this.formInput.conditionConfig.options != null &&
        this.formInput.conditionConfig.options.length > 0
      ) {
        condition_config = this.formInput.conditionConfig;
        condition_config.config_url = "bytag";
        condition_config.options.forEach(function (ele) {
          ele.multi_condition.forEach((cond) => {
            // Validate date range
            if (cond.expression == "date_range") {
              if (cond.range.start != "") {
                cond.range.start = self.$dateTime.toBackendTimeFormat(
                  cond.range.start
                );
              }
              if (cond.range.end != "") {
                cond.range.end = self.$dateTime.toBackendTimeFormat(
                  cond.range.end
                );
              }
            }
          });
        });
        if (nodeName == "") {
          nodeName = this.$t("__MultiCondition");
        }
      }

      // Reservation config
      let reservations_config = [];
      if (this.formInput.reservations.length > 0) {
        this.formInput.reservations.forEach((reservation) => {
          let rettime = 0;
          if (reservation.scheduletime) {
            rettime = reservation.scheduletime;
          }
          switch (reservation.scheduleunit) {
            case "days":
              rettime = rettime * 1440;
              break;
            case "hours":
              rettime = rettime * 60;
              break;
          }
          reservations_config.push({
            scenario_id: reservation.schedule,
            reservationtime: rettime,
            window: {
              start: reservation.window.start,
              end: reservation.window.end,
            },
          });
          if (reservation.schedule == "" || Number.isNaN(rettime)) {
            error = 1;
          }
        });
      }

      // Condition content validation
      if (this.formInput.nodeType == "Condition") {
        nodeType = "condition";

        // If URL is not set, see it as error
        if (
          this.segmentInput == null &&
          this.formInput.conditionConfig == null &&
          this.condInput != null &&
          (this.condInput.config_url == null || this.condInput.config_url == "")
        ) {
          error = 1;
        }
        // If URL condition has content, check all required field.
        if (
          this.condInput != null &&
          this.condInput.options != null &&
          this.condInput.options.length > 0
        ) {
          this.condInput.options.forEach(function (ele) {
            if (ele.value == null || ele.value == "") {
              error = 1;
            }
            if (
              ele.nextnodeType == "nnode" &&
              (ele.nextNodeId == null || ele.nextNodeId == "")
            ) {
              error = 1;
            }
            if (
              ele.nextnodeType == "snode" &&
              (ele.scenario_id == null || ele.scenario_id == "")
            ) {
              error = 1;
            }
          });
        }
        // If segment condition has content, check all required field.
        if (
          this.segmentInput != null &&
          this.segmentInput.options != null &&
          this.segmentInput.options.length > 0
        ) {
          this.segmentInput.options.forEach(function (ele) {
            if (ele.value == null || ele.value == "") {
              error = 1;
            }
            if (
              ele.nextnodeType == "nnode" &&
              (ele.nextNodeId == null || ele.nextNodeId == "")
            ) {
              error = 1;
            }
            if (
              ele.nextnodeType == "snode" &&
              (ele.scenario_id == null || ele.scenario_id == "")
            ) {
              error = 1;
            }
          });
        }
        // If tag condition has content, check all required field.
        if (
          this.formInput.conditionConfig != null &&
          this.formInput.conditionConfig.options != null &&
          this.formInput.conditionConfig.options.length > 0
        ) {
          this.formInput.conditionConfig.options.forEach(function (ele) {
            if (ele.multi_condition) {
              ele.multi_condition.forEach((cond) => {
                if (cond.source == "tag") {
                  // Didn't select tag. show error.
                  if (cond.tag == "") {
                    error = 1;
                  } else if (cond.tag.name == "" || cond.tag.color == "") {
                    error = 1;
                  }
                } else if (
                  (cond.source == "uservariable") &
                  (cond.uservariable == "")
                ) {
                  error = 1;
                } else if (
                  (cond.expression == "exactly_match" && cond.value == "") ||
                  (cond.expression == "fuzzy_match" && cond.value == "")
                ) {
                  error = 1;
                }
                // Validate date range
                if (
                  (cond.expression == "date_range") &
                  (cond.range.start == "" || cond.range.end == "")
                ) {
                  error = 1;
                }
              });
            }
            if (
              ele.nextnodeType == "nnode" &&
              (ele.nextNodeId == null || ele.nextNodeId == "")
            ) {
              error = 1;
            }
            if (
              ele.nextnodeType == "snode" &&
              (ele.scenario_id == null || ele.scenario_id == "")
            ) {
              error = 1;
            }
          });
        }
      }
      // User Reply
      let userReply = {
        variable: {},
        validate: this.formInput.userAction.userReply.validate,
        encryption: this.formInput.userAction.userReply.encryption,
        selectCompare: this.formInput.userAction.userReply.selectCompare,
        lensCompare: this.formInput.userAction.userReply.lensCompare,
      };
      if (userReply.validate.success.nextNodeId !== "") {
        userReply.validate.success.scenarioId = this.scenarioId;
      }
      if (userReply.validate.failed.nextNodeId !== "") {
        userReply.validate.failed.scenarioId = this.scenarioId;
      }
      this.formInput.userAction.userReply.variables.forEach((variable) => {
        userReply.variable[variable] = {
          name: variable,
        };
      });
      // Remove Variable
      let emptyVariable = {
        variable: {},
      };
      this.formInput.userAction.emptyVariable.variables.forEach((variable) => {
        emptyVariable.variable[variable] = {
          name: variable,
        };
      });
      // Check varForms
      let varForms = [];
      this.formInput.varForms.forEach(varForm => {
        let exisFormIndex = this.allVarForms.findIndex(form => form.id === varForm.formId);
        if (exisFormIndex > -1) {
          varForms.push(varForm);
        }
      });

      // template form
      if (this.formInput.template && this.formInput.template.form) {
          flextemplate.form = this.formInput.template.form;
          console.log("flextemplate.form", flextemplate.form);
      }


      let newNode = {
        type: nodeType,
        level: this.nodeData.level.toString(),
        condition_config: condition_config,
        reservations_config: reservations_config,
        nextScenario: nextScenario,
        nextNode: nextNode,
        nodeId: nodeId,
        nodeTag: nodeTag,
        sort: this.getNewSort(this.nodeData.level),
        template: flextemplate,
        flex_config: flexConfig,
        title: nodeName,
        subtitle: this.formInput.subtitle,
        error: error,
        userAction: {
          userReply: userReply,
          emptyVariable: emptyVariable,
        },
        nodeConst: this.formInput.constant,
        externalApi: this.formInput.externalApi,
        apps: this.formInput.apps,
        varForms: varForms,
        datetimeTrigger: this.formInput.datetimeTrigger
      };

      // Pass saving date to method childToParent in story/Component/Nodes.vue
      this.$emit("childToParent", newNode);
      $("#nodeModal").modal("hide");
    },
    // Get value array from all tags
    valuesOfTag(color, name) {
      var values = [];
      let tag = this.allTags.find(
        (tag) => tag.color === color && tag.name === name
      );
      if (tag) {
        values = tag.values;
      }
      return values;
    },


    // Add variable to create & edit node
    addVariable(variables) {
      switch (this.variablesSelectMode) {
        case "UserReply":
          this.formInput.userAction.userReply.variables.splice(0);
          variables.forEach((variable) => {
            this.formInput.userAction.userReply.variables.push(variable);
          });
          break;
        case "RemoveVariable":
          this.formInput.userAction.emptyVariable.variables.splice(0);
          variables.forEach((variable) => {
            this.formInput.userAction.emptyVariable.variables.push(variable);
          });
          break;
        default:
          break;
      }
    },
    // Display form creation modal
    showCreateFormModal(formObject) {
      this.formObjectModel = formObject;
      $("#var-form-modal").modal("show");
    },
    saveForm(formId, formName, formVariables) {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        name: formName,
      };
      let variableFields = []
      formVariables.forEach(variable => {
        variableFields.push({
          var_name: variable
        })
      });
      formData["fields"] = variableFields;
      if (formId && formId != "") {
        formData["formId"] = formId;
      }
      apiFunction.saveForm(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          });
          this.$router.push("/login");
        } else {
          let replyFormId = data.data.Result.data.id;
          let formIndex = this.allVarForms.findIndex(form => form.id === replyFormId);
          if (formIndex > -1) {
            this.allVarForms[formIndex] = data.data.Result.data
            console.log("this.allVarForms", this.allVarForms);
          } else {
            this.allVarForms.push(data.data.Result.data);
          }
          $("#var-form-modal").modal("hide");
        }
      });
    },
    deleteForm(formId) {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        formId: formId,
      };

      this.$fire({
        title: this.$t("__Areyousureyouwanttodeleteit"),
        text: this.$t("__Thesystemwillnotbeabletoretrieveyourdata"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("__YesIwanttodelete"),
        cancelButtonText: this.$t("__cancel"),
      }).then((result) => {
        if (result.value) {
          apiFunction.deleteForm(formData, this.jwtToken).then((data) => {
            if (data.error) {
              this.$fire({
                title: this.$t("__failedtodelete"),
                text: this.$t("__oopsSomethingwentwrong"),
                type: "error",
              });
            } else {
              this.$fire({
                title: this.$t("__successfullydeleted"),
                text: this.$t("__Yourdatahasbeensuccessfullydeleted"),
                type: "success",
                timer: 1500,
              })
              // Update allVarForms.
              let exisFormIndex = this.allVarForms.findIndex(form => form.id === formId);
              if (exisFormIndex > -1) {
                this.allVarForms.splice(exisFormIndex, 1);
              }
              // Update varForms in formInput if the formId is exist.
              let varFormIndex = this.formInput.varForms.findIndex(form => form.formId === formId);
              if (varFormIndex > -1) {
                this.formInput.varForms.splice(varFormIndex, 1);
              }
            }
          });
        }
      });
    },
    addButtonVar(btnIndex) {
      this.formInput.button[btnIndex].userVars.push({
        variable: "",
        value: "",
      });
    },
    addButtonRegex(regexIndex) {
      this.formInput.userAction.userReply.validate.inputList.push({
        type: null,
        value: "",
      });
    },
    deleteButtonVar(btnIndex, varIndex) {
      this.formInput.button[btnIndex].userVars.splice(varIndex, 1);
    },
    // Display exist variable modal
    showExistVariable(mode) {
      this.variablesSelectMode = mode;
      $("#variable").modal("show");
    },

    // Pass new variable
    newVariable(newVariable) {
      if (this.allVariables.find((variable) => variable === newVariable)) {
        $("#variable").modal("show");
        return;
      }
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        accountVariable: [newVariable],
      };
      apiFunction.saveVariable(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          });
          this.$router.push("/login");
        } else {
          this.allVariables.push(newVariable);
          $("#variable").modal("show");
        }
      });
    },
    removeVariable(variable) {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        accountVariable: [variable],
      };
      apiFunction.deleteVariable(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          });
          this.$router.push("/login");
        }
      });
    },
    getCrosErrorMsg(code) {
      let msg = "";
      let error = this.crosApiErrs.find((err) => err.code === code);
      if (error) {
        msg = error.msg;
      }
      return msg;
    },
    editSubTitle() {
      $("#node-subTitle").hide();
      $("#node-subTitle-input").show();
      $("#node-subTitle-edit").hide();
    },
    editSubTitleDone($event) {
      if ($event.which == 13) {
        $("#node-subTitle").show();
        $("#node-subTitle-input").hide();
        $("#node-subTitle-edit").show();
        return false;
      }
    },
    formFieldVisible(type) {
      let visibletypes = [];

      if(arguments[1] != null && arguments[1].length >=1 && arguments[1] == 'all'){
        if(type == 'submit'){
          return false;
        }
        return true;
      }

      for(let i=1;i<arguments.length;i++){
        visibletypes.push(arguments[i]);
      }

      let r =  visibletypes.includes(type);

      return r;
    },
  },
};
</script>

<style scope>
input[type="radio"] + label {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  box-shadow: 2px 2px 0px rgba(51, 51, 51, 0.2);
  transition: transform 0.2s ease;

  margin-left: 20px;
  margin-right: 10px;
}

.tag {
  color: #ffffff !important;
}

.error {
  background-color: lightpink !important;
}
</style>
