var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "modal fade bd-example-modal-lg",
          attrs: {
            id: "nodeModal",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "exampleModalCenterTitle",
            "aria-hidden": "true",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-lg FormScenario",
              attrs: { role: "document" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-content tag-modal",
                  staticStyle: { width: "1000px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "modal-header",
                      staticStyle: { "align-items": "center" },
                    },
                    [
                      this.nodeData.nodeId != ""
                        ? _c("span", [
                            _vm._v(
                              " Form " +
                                _vm._s(_vm.$t("__modalTitleNodeId")) +
                                ": " +
                                _vm._s(this.nodeData.nodeId) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm._m(0),
                    ]
                  ),
                  _c("div", { staticClass: "modal-body" }, [
                    _c(
                      "div",
                      { staticClass: "row px-3 py-2" },
                      [
                        _vm._l(_vm.formInput.tags, function (tag, index) {
                          return _c(
                            "ul",
                            {
                              key: index,
                              staticClass: "tags",
                              attrs: { id: "node-tag-area" },
                            },
                            [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "tag",
                                    class: tag.color,
                                    attrs: {
                                      href: "javascript:void(0)",
                                      id: "newLevelTag",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteTag(tag)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(tag.name))]
                                ),
                              ]),
                            ]
                          )
                        }),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-outline-secondary btn-sm ml-auto",
                            attrs: {
                              "data-toggle": "modal",
                              "data-target": "#tag",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showExistTag()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("__Addlabel")) + " ")]
                        ),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "row px-3 mb-1" }, [
                      _vm.formInput.subTitle !== ""
                        ? _c(
                            "p",
                            {
                              staticClass: "text-bold",
                              attrs: { id: "node-subTitle" },
                            },
                            [_vm._v(" " + _vm._s(_vm.formInput.subtitle) + " ")]
                          )
                        : _vm._e(),
                      _vm.formInput.subtitle == ""
                        ? _c(
                            "p",
                            {
                              staticClass: "text-bold",
                              attrs: { id: "node-subTitle" },
                            },
                            [_vm._v(" Subtitle ")]
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          attrs: { id: "node-subTitle-edit" },
                          on: {
                            click: function ($event) {
                              return _vm.editSubTitle()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-pencil-alt cursor-pointer pl-1",
                          }),
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formInput.subtitle,
                            expression: "formInput.subtitle",
                          },
                        ],
                        staticClass: "form-control element-hide",
                        attrs: { type: "text", id: "node-subTitle-input" },
                        domProps: { value: _vm.formInput.subtitle },
                        on: {
                          keypress: function ($event) {
                            return _vm.editSubTitleDone($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formInput,
                              "subtitle",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "row border p-3",
                        staticStyle: { height: "65vh", overflow: "auto" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "D w-100",
                            attrs: { id: "v-pills-tabContent" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "tab-pane fade show active",
                                attrs: {
                                  id: "v-pills-formsetting",
                                  role: "tabpanel",
                                  "aria-labelledby": "v-pills-formsetting-tab",
                                },
                              },
                              [
                                _c("div", { staticClass: "pt-5" }, [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.$t("__utilsType"))),
                                  ]),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.formInput.template.form.type,
                                          expression:
                                            "formInput.template.form.type",
                                        },
                                      ],
                                      staticClass: "form-control col-8",
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.formInput.template.form,
                                              "type",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          _vm.onTypeChange,
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "label" } },
                                        [_vm._v(_vm._s(_vm.$t("__label")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "caption" } },
                                        [_vm._v(_vm._s(_vm.$t("__caption")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "dropdown" } },
                                        [_vm._v(_vm._s(_vm.$t("__dropdown")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "dropdown2" } },
                                        [_vm._v(_vm._s(_vm.$t("__dropdown2")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "dropdownMerge" } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("__dropdownMerge"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "text" } },
                                        [_vm._v(_vm._s(_vm.$t("__text")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "textarea" } },
                                        [_vm._v(_vm._s(_vm.$t("__textarea")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "button" } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("__buttonSingle"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "buttonMultiple" } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("__buttonMultiple"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "date" } },
                                        [_vm._v(_vm._s(_vm.$t("__date")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "time" } },
                                        [_vm._v(_vm._s(_vm.$t("__time")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "datetime-local" } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("__datetime_local"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "email" } },
                                        [_vm._v(_vm._s(_vm.$t("__email")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "number" } },
                                        [_vm._v(_vm._s(_vm.$t("__number")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "password" } },
                                        [_vm._v(_vm._s(_vm.$t("__password")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "tel" } },
                                        [_vm._v(_vm._s(_vm.$t("__tel")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "url" } },
                                        [_vm._v(_vm._s(_vm.$t("__url")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "search" } },
                                        [_vm._v(_vm._s(_vm.$t("__search")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "color" } },
                                        [_vm._v(_vm._s(_vm.$t("__color")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "range" } },
                                        [_vm._v(_vm._s(_vm.$t("__range")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "file" } },
                                        [_vm._v(_vm._s(_vm.$t("__file")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "multiple" } },
                                        [_vm._v(_vm._s(_vm.$t("__multiple")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "checkbox" } },
                                        [_vm._v(_vm._s(_vm.$t("__checkbox")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "radio" } },
                                        [_vm._v(_vm._s(_vm.$t("__radio")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "hidden" } },
                                        [_vm._v(_vm._s(_vm.$t("__hidden")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "month" } },
                                        [_vm._v(_vm._s(_vm.$t("__month")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "week" } },
                                        [_vm._v(_vm._s(_vm.$t("__week")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "reset" } },
                                        [_vm._v(_vm._s(_vm.$t("__reset")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "image" } },
                                        [_vm._v(_vm._s(_vm.$t("__image")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "datalist" } },
                                        [_vm._v(_vm._s(_vm.$t("__datalist")))]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "submit" } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("__submitbutton"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c("br"),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v("*"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("__requiredItem")) +
                                        " "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "form-row px-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-2" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-check-label",
                                            attrs: {
                                              for: "isRequiredCheckbox",
                                            },
                                          },
                                          [_vm._v("是否啟用")]
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.formInput.template.form
                                                  .enable,
                                              expression:
                                                "formInput.template.form.enable",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          staticStyle: {
                                            transform: "scale(0.5)",
                                            "margin-right": "10px",
                                          },
                                          attrs: {
                                            type: "checkbox",
                                            id: "enableCheckbox",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.formInput.template.form.enable
                                            )
                                              ? _vm._i(
                                                  _vm.formInput.template.form
                                                    .enable,
                                                  null
                                                ) > -1
                                              : _vm.formInput.template.form
                                                  .enable,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.formInput.template.form
                                                    .enable,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.formInput.template
                                                        .form,
                                                      "enable",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.formInput.template
                                                        .form,
                                                      "enable",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.formInput.template.form,
                                                  "enable",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm.formInput.template.form.type !==
                                    "submit"
                                      ? _c(
                                          "div",
                                          { staticClass: "form-group col-2" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "form-check-label",
                                                attrs: {
                                                  for: "enableRenderCheckbox",
                                                },
                                              },
                                              [_vm._v("是否隱藏")]
                                            ),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formInput.template.form
                                                      .enableRender,
                                                  expression:
                                                    "formInput.template.form.enableRender",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              staticStyle: {
                                                transform: "scale(0.5)",
                                                "margin-right": "10px",
                                              },
                                              attrs: {
                                                type: "checkbox",
                                                id: "enableRenderCheckbox",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.formInput.template.form
                                                    .enableRender
                                                )
                                                  ? _vm._i(
                                                      _vm.formInput.template
                                                        .form.enableRender,
                                                      null
                                                    ) > -1
                                                  : _vm.formInput.template.form
                                                      .enableRender,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.formInput.template
                                                        .form.enableRender,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.formInput.template
                                                            .form,
                                                          "enableRender",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.formInput.template
                                                            .form,
                                                          "enableRender",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.formInput.template
                                                        .form,
                                                      "enableRender",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm.formInput.template.form.enable == true
                                    ? _c(
                                        "div",
                                        { staticClass: "border p-2 mb-2" },
                                        [
                                          _c("br"),
                                          _vm.formFieldVisible(
                                            _vm.formInput.template.form.type,
                                            "button",
                                            "dropdown",
                                            "dropdown2",
                                            "buttonMultiple",
                                            "date",
                                            "dropdownMerge",
                                            "checkbox",
                                            "datalist",
                                            "number",
                                            "textarea",
                                            "radio",
                                            "text"
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "form-row px-4",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4",
                                                    },
                                                    [
                                                      _c("label", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-danger",
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__titlename"
                                                              )
                                                            )
                                                        ),
                                                      ]),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .template.form
                                                                .title,
                                                            expression:
                                                              "formInput.template.form.title",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value:
                                                            _vm.formInput
                                                              .template.form
                                                              .title,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.formInput
                                                                .template.form,
                                                              "title",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.formFieldVisible(
                                            _vm.formInput.template.form.type,
                                            "button",
                                            "dropdown",
                                            "dropdown2",
                                            "dropdownMerge",
                                            "checkbox",
                                            "radio",
                                            "button",
                                            "datalist",
                                            "buttonMultiple",
                                            "text"
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "form-row px-4",
                                                },
                                                [
                                                  _vm.formInput.template.form
                                                    .type !== "submit"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-2",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form-check-label",
                                                              attrs: {
                                                                for: "isRequiredCheckbox",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__requiredField"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .isRequired,
                                                                expression:
                                                                  "formInput.template.form.isRequired",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              transform:
                                                                "scale(0.5)",
                                                              "margin-right":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              type: "checkbox",
                                                              id: "isRequiredCheckbox",
                                                            },
                                                            domProps: {
                                                              checked:
                                                                Array.isArray(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .isRequired
                                                                )
                                                                  ? _vm._i(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form
                                                                        .isRequired,
                                                                      null
                                                                    ) > -1
                                                                  : _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .isRequired,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .isRequired,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "isRequired",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "isRequired",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "isRequired",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          false &&
                                          _vm.formFieldVisible(
                                            _vm.formInput.template.form.type,
                                            "button",
                                            "dropdown",
                                            "dropdown2",
                                            "dropdownMerge",
                                            "checkbox",
                                            "radio",
                                            "button",
                                            "datalist",
                                            "buttonMultiple"
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "form-row px-4",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-3",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label",
                                                          attrs: {
                                                            for: "allowAddValue",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__allowAddValue"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .template.form
                                                                .allowAddValue,
                                                            expression:
                                                              "formInput.template.form.allowAddValue",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        staticStyle: {
                                                          transform:
                                                            "scale(0.5)",
                                                        },
                                                        attrs: {
                                                          type: "checkbox",
                                                          id: "allowAddValue",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.formInput
                                                                .template.form
                                                                .allowAddValue
                                                            )
                                                              ? _vm._i(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .allowAddValue,
                                                                  null
                                                                ) > -1
                                                              : _vm.formInput
                                                                  .template.form
                                                                  .allowAddValue,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.formInput
                                                                  .template.form
                                                                  .allowAddValue,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "allowAddValue",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "allowAddValue",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.formInput
                                                                  .template
                                                                  .form,
                                                                "allowAddValue",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-3",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label",
                                                          attrs: {
                                                            for: "allowMultiple",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__allowMultiple"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .template.form
                                                                .allowMultiple,
                                                            expression:
                                                              "formInput.template.form.allowMultiple",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        staticStyle: {
                                                          transform:
                                                            "scale(0.5)",
                                                        },
                                                        attrs: {
                                                          type: "checkbox",
                                                          id: "allowMultiple",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.formInput
                                                                .template.form
                                                                .allowMultiple
                                                            )
                                                              ? _vm._i(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .allowMultiple,
                                                                  null
                                                                ) > -1
                                                              : _vm.formInput
                                                                  .template.form
                                                                  .allowMultiple,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.formInput
                                                                  .template.form
                                                                  .allowMultiple,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "allowMultiple",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "allowMultiple",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.formInput
                                                                  .template
                                                                  .form,
                                                                "allowMultiple",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4",
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__multipleSubformField"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .template.form
                                                                .multiplesubformfield,
                                                            expression:
                                                              "formInput.template.form.multiplesubformfield",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value:
                                                            _vm.formInput
                                                              .template.form
                                                              .multiplesubformfield,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.formInput
                                                                .template.form,
                                                              "multiplesubformfield",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.formFieldVisible(
                                            _vm.formInput.template.form.type,
                                            "button",
                                            "dropdown",
                                            "dropdown2",
                                            "dropdownMerge",
                                            "checkbox",
                                            "radio",
                                            "button",
                                            "datalist",
                                            "buttonMultiple",
                                            "hidden"
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "form-row px-4",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class: {
                                                        "form-group col-2":
                                                          _vm.formInput.template
                                                            .form.type ===
                                                          "checkbox",
                                                        "form-group col-6":
                                                          _vm.formInput.template
                                                            .form.type !==
                                                          "checkbox",
                                                      },
                                                    },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__defaultValue"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _vm.formInput.template
                                                        .form.type ===
                                                      "checkbox"
                                                        ? _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .defaultValue,
                                                                expression:
                                                                  "formInput.template.form.defaultValue",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              transform:
                                                                "scale(0.5)",
                                                              "margin-right":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              type: "checkbox",
                                                            },
                                                            domProps: {
                                                              checked:
                                                                _vm.formInput
                                                                  .template.form
                                                                  .defaultValue,
                                                              checked:
                                                                Array.isArray(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .defaultValue
                                                                )
                                                                  ? _vm._i(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form
                                                                        .defaultValue,
                                                                      null
                                                                    ) > -1
                                                                  : _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .defaultValue,
                                                            },
                                                            on: {
                                                              change: [
                                                                function (
                                                                  $event
                                                                ) {
                                                                  var $$a =
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form
                                                                        .defaultValue,
                                                                    $$el =
                                                                      $event.target,
                                                                    $$c =
                                                                      $$el.checked
                                                                        ? true
                                                                        : false
                                                                  if (
                                                                    Array.isArray(
                                                                      $$a
                                                                    )
                                                                  ) {
                                                                    var $$v =
                                                                        null,
                                                                      $$i =
                                                                        _vm._i(
                                                                          $$a,
                                                                          $$v
                                                                        )
                                                                    if (
                                                                      $$el.checked
                                                                    ) {
                                                                      $$i < 0 &&
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formInput
                                                                            .template
                                                                            .form,
                                                                          "defaultValue",
                                                                          $$a.concat(
                                                                            [
                                                                              $$v,
                                                                            ]
                                                                          )
                                                                        )
                                                                    } else {
                                                                      $$i >
                                                                        -1 &&
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formInput
                                                                            .template
                                                                            .form,
                                                                          "defaultValue",
                                                                          $$a
                                                                            .slice(
                                                                              0,
                                                                              $$i
                                                                            )
                                                                            .concat(
                                                                              $$a.slice(
                                                                                $$i +
                                                                                  1
                                                                              )
                                                                            )
                                                                        )
                                                                    }
                                                                  } else {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form,
                                                                      "defaultValue",
                                                                      $$c
                                                                    )
                                                                  }
                                                                },
                                                                _vm.handleInputChange,
                                                              ],
                                                            },
                                                          })
                                                        : _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .defaultValue,
                                                                expression:
                                                                  "formInput.template.form.defaultValue",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.formInput
                                                                  .template.form
                                                                  .defaultValue,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form,
                                                                  "defaultValue",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.formFieldVisible(
                                            _vm.formInput.template.form.type,
                                            "date"
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "form-row px-4",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      class: {
                                                        "form-group col-2":
                                                          _vm.formInput.template
                                                            .form.type ===
                                                          "checkbox",
                                                        "form-group col-6":
                                                          _vm.formInput.template
                                                            .form.type !==
                                                          "checkbox",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label",
                                                          attrs: {
                                                            for: "isDefaultTodayCheckbox",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__defaultToday"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .template.form
                                                                .isDefaultToday,
                                                            expression:
                                                              "formInput.template.form.isDefaultToday",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        staticStyle: {
                                                          transform:
                                                            "scale(0.5)",
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          type: "checkbox",
                                                          id: "isDefaultTodayCheckbox",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.formInput
                                                                .template.form
                                                                .isDefaultToday
                                                            )
                                                              ? _vm._i(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .isDefaultToday,
                                                                  null
                                                                ) > -1
                                                              : _vm.formInput
                                                                  .template.form
                                                                  .isDefaultToday,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.formInput
                                                                  .template.form
                                                                  .isDefaultToday,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "isDefaultToday",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "isDefaultToday",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.formInput
                                                                  .template
                                                                  .form,
                                                                "isDefaultToday",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.formFieldVisible(
                                            _vm.formInput.template.form.type,
                                            "button",
                                            "dropdown",
                                            "dropdown2",
                                            "dropdownMerge",
                                            "checkbox",
                                            "radio",
                                            "button",
                                            "datalist",
                                            "buttonMultiple"
                                          )
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row px-4",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-5",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(" key"),
                                                          ]),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form.key,
                                                                  expression:
                                                                    "formInput.template.form.key",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form,
                                                                      "key",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                    selected:
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__UserVariableSelectOptional"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._l(
                                                                _vm.allVariables,
                                                                function (
                                                                  value,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key:
                                                                        "variable-" +
                                                                        index,
                                                                      domProps:
                                                                        {
                                                                          value:
                                                                            value,
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            value
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.accountType == "line"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-5",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  " value"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form
                                                                          .value,
                                                                      expression:
                                                                        "formInput.template.form.value",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal =
                                                                          Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formInput
                                                                            .template
                                                                            .form,
                                                                          "value",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "",
                                                                        selected:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__UserVariableSelectOptional"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._l(
                                                                    _vm.allVariables,
                                                                    function (
                                                                      value,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            "variable-" +
                                                                            index,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                value,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                value
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row px-4",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-2",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form-check-label",
                                                              attrs: {
                                                                for: "enableOrder",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__enableOrder"
                                                                    )
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enableorder,
                                                                expression:
                                                                  "formInput.template.form.enableorder",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              transform:
                                                                "scale(0.5)",
                                                              "margin-right":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              type: "checkbox",
                                                              id: "enableOrder",
                                                            },
                                                            domProps: {
                                                              checked:
                                                                Array.isArray(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enableorder
                                                                )
                                                                  ? _vm._i(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form
                                                                        .enableorder,
                                                                      null
                                                                    ) > -1
                                                                  : _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .enableorder,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .enableorder,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "enableorder",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "enableorder",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "enableorder",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm.formInput.template
                                                        .form.enableorder ==
                                                      true
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-3",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__ordertype"
                                                                      )
                                                                    )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form
                                                                          .ordertype,
                                                                      expression:
                                                                        "formInput.template.form.ordertype",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal =
                                                                          Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formInput
                                                                            .template
                                                                            .form,
                                                                          "ordertype",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "asc",
                                                                        selected:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__AscendingOrder"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "desc",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "__DescendingOrder"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.formInput.template
                                                        .form.enableorder ==
                                                      true
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-5",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__orderfield"
                                                                      )
                                                                    )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form
                                                                          .orderfield,
                                                                      expression:
                                                                        "formInput.template.form.orderfield",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal =
                                                                          Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formInput
                                                                            .template
                                                                            .form,
                                                                          "orderfield",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "",
                                                                        selected:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__UserVariableSelectOptional"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._l(
                                                                    _vm.allVariables,
                                                                    function (
                                                                      value,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            "variable-" +
                                                                            index,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                value,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                value
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row px-4",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-2",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form-check-label",
                                                              attrs: {
                                                                for: "enableFilterNode",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__enableFilterNode"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enablefilternode,
                                                                expression:
                                                                  "formInput.template.form.enablefilternode",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              transform:
                                                                "scale(0.5)",
                                                              "margin-right":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              type: "checkbox",
                                                              id: "enableFilterNode",
                                                            },
                                                            domProps: {
                                                              checked:
                                                                Array.isArray(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enablefilternode
                                                                )
                                                                  ? _vm._i(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form
                                                                        .enablefilternode,
                                                                      null
                                                                    ) > -1
                                                                  : _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .enablefilternode,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .enablefilternode,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "enablefilternode",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "enablefilternode",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "enablefilternode",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm.formInput.template
                                                        .form
                                                        .enablefilternode ==
                                                      true
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-4",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__setFilterNode"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form
                                                                          .filternodeId,
                                                                      expression:
                                                                        "formInput.template.form.filternodeId",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal =
                                                                          Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formInput
                                                                            .template
                                                                            .form,
                                                                          "filternodeId",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "",
                                                                        selected:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__NextNodeoptional"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._l(
                                                                    _vm.allNodes,
                                                                    function (
                                                                      node,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key: index,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                node.nodeId,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                node.title.substr(
                                                                                  0,
                                                                                  25
                                                                                )
                                                                              ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                node.subtitle
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.formInput.template
                                                        .form
                                                        .enablefilternode ==
                                                      true
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-4",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__subformlookupFiled"
                                                                      )
                                                                    )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form
                                                                          .subformlookupfiled,
                                                                      expression:
                                                                        "formInput.template.form.subformlookupfiled",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal =
                                                                          Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formInput
                                                                            .template
                                                                            .form,
                                                                          "subformlookupfiled",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "",
                                                                        selected:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__UserVariableSelectOptional"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._l(
                                                                    _vm.allVariables,
                                                                    function (
                                                                      value,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            "variable-" +
                                                                            index,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                value,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                value
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row px-4",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-2",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form-check-label",
                                                              attrs: {
                                                                for: "enableAuxiliarySelection",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__enableAuxiliarySelection"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enableAuxiliarySelection,
                                                                expression:
                                                                  "formInput.template.form.enableAuxiliarySelection",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              transform:
                                                                "scale(0.5)",
                                                              "margin-right":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              type: "checkbox",
                                                              id: "enableAuxiliarySelection",
                                                            },
                                                            domProps: {
                                                              checked:
                                                                Array.isArray(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enableAuxiliarySelection
                                                                )
                                                                  ? _vm._i(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form
                                                                        .enableAuxiliarySelection,
                                                                      null
                                                                    ) > -1
                                                                  : _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .enableAuxiliarySelection,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .enableAuxiliarySelection,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "enableAuxiliarySelection",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "enableAuxiliarySelection",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "enableAuxiliarySelection",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _vm.formInput.template
                                                        .form
                                                        .enableAuxiliarySelection ==
                                                      true
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-4",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__auxiliarySelectionNode"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form
                                                                          .auxiliarySelectionNodeId,
                                                                      expression:
                                                                        "formInput.template.form.auxiliarySelectionNodeId",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal =
                                                                          Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formInput
                                                                            .template
                                                                            .form,
                                                                          "auxiliarySelectionNodeId",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "",
                                                                        selected:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__NextNodeoptional"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._l(
                                                                    _vm.allNodes,
                                                                    function (
                                                                      node,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key: index,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                node.nodeId,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                node.title.substr(
                                                                                  0,
                                                                                  25
                                                                                )
                                                                              ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                node.subtitle
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.formInput.template
                                                        .form
                                                        .enableAuxiliarySelection ==
                                                      true
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-4",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__auxiliarySelectionField"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form
                                                                          .auxiliarySelectionField,
                                                                      expression:
                                                                        "formInput.template.form.auxiliarySelectionField",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal =
                                                                          Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formInput
                                                                            .template
                                                                            .form,
                                                                          "auxiliarySelectionField",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "",
                                                                        selected:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__UserVariableSelectOptional"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._l(
                                                                    _vm.allVariables,
                                                                    function (
                                                                      value,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            "variable-" +
                                                                            index,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                value,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                value
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row px-4",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-2",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form-check-label",
                                                              attrs: {
                                                                for: "enableAdditionalEntryField",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__enableAdditionalEntryField"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .allowAdditionalEntryField,
                                                                expression:
                                                                  "formInput.template.form.allowAdditionalEntryField",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              transform:
                                                                "scale(0.5)",
                                                            },
                                                            attrs: {
                                                              type: "checkbox",
                                                              id: "enableAdditionalEntryField",
                                                            },
                                                            domProps: {
                                                              checked:
                                                                Array.isArray(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .allowAdditionalEntryField
                                                                )
                                                                  ? _vm._i(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form
                                                                        .allowAdditionalEntryField,
                                                                      null
                                                                    ) > -1
                                                                  : _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .allowAdditionalEntryField,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .allowAdditionalEntryField,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "allowAdditionalEntryField",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "allowAdditionalEntryField",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "allowAdditionalEntryField",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.formInput.template.form
                                                    .allowAdditionalEntryField ==
                                                  true
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row mb-2 align-items-center",
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "col-2 my-2",
                                                          }),
                                                          _vm._m(1),
                                                          _vm._m(2),
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "text-info m-1",
                                                              attrs: {
                                                                href: "javascript:void(0)",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.addAdditionalEntry()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-plus text-info",
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._l(
                                                    _vm.formInput.template.form
                                                      .additionalEntries,
                                                    function (entry, index) {
                                                      return _vm.formInput
                                                        .template.form
                                                        .allowAdditionalEntryField ==
                                                        true
                                                        ? _c(
                                                            "div",
                                                            {
                                                              key:
                                                                "entry-" +
                                                                index,
                                                              staticClass:
                                                                "row mb-2",
                                                            },
                                                            [
                                                              _c("div", {
                                                                staticClass:
                                                                  "col-2 my-2",
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-4 my-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "select",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              entry.parameter,
                                                                            expression:
                                                                              "entry.parameter",
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "form-control",
                                                                      attrs: {
                                                                        id:
                                                                          "additional-parameter-" +
                                                                          index,
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            var $$selectedVal =
                                                                              Array.prototype.filter
                                                                                .call(
                                                                                  $event
                                                                                    .target
                                                                                    .options,
                                                                                  function (
                                                                                    o
                                                                                  ) {
                                                                                    return o.selected
                                                                                  }
                                                                                )
                                                                                .map(
                                                                                  function (
                                                                                    o
                                                                                  ) {
                                                                                    var val =
                                                                                      "_value" in
                                                                                      o
                                                                                        ? o._value
                                                                                        : o.value
                                                                                    return val
                                                                                  }
                                                                                )
                                                                            _vm.$set(
                                                                              entry,
                                                                              "parameter",
                                                                              $event
                                                                                .target
                                                                                .multiple
                                                                                ? $$selectedVal
                                                                                : $$selectedVal[0]
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "option",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              value:
                                                                                "",
                                                                              selected:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "__UserVariableSelectOptional"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._l(
                                                                        _vm.allVariables,
                                                                        function (
                                                                          value,
                                                                          idx
                                                                        ) {
                                                                          return _c(
                                                                            "option",
                                                                            {
                                                                              key:
                                                                                "parameter-" +
                                                                                idx,
                                                                              domProps:
                                                                                {
                                                                                  value:
                                                                                    value,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    value
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                    ],
                                                                    2
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "row col-5 my-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "select",
                                                                    {
                                                                      directives:
                                                                        [
                                                                          {
                                                                            name: "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              entry.variable,
                                                                            expression:
                                                                              "entry.variable",
                                                                          },
                                                                        ],
                                                                      staticClass:
                                                                        "form-control",
                                                                      attrs: {
                                                                        id:
                                                                          "additional-variable-" +
                                                                          index,
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            var $$selectedVal =
                                                                              Array.prototype.filter
                                                                                .call(
                                                                                  $event
                                                                                    .target
                                                                                    .options,
                                                                                  function (
                                                                                    o
                                                                                  ) {
                                                                                    return o.selected
                                                                                  }
                                                                                )
                                                                                .map(
                                                                                  function (
                                                                                    o
                                                                                  ) {
                                                                                    var val =
                                                                                      "_value" in
                                                                                      o
                                                                                        ? o._value
                                                                                        : o.value
                                                                                    return val
                                                                                  }
                                                                                )
                                                                            _vm.$set(
                                                                              entry,
                                                                              "variable",
                                                                              $event
                                                                                .target
                                                                                .multiple
                                                                                ? $$selectedVal
                                                                                : $$selectedVal[0]
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "option",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              value:
                                                                                "",
                                                                              selected:
                                                                                "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "__UserVariableSelectOptional"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._l(
                                                                        _vm.allVariables,
                                                                        function (
                                                                          value,
                                                                          idx
                                                                        ) {
                                                                          return _c(
                                                                            "option",
                                                                            {
                                                                              key:
                                                                                "variable-" +
                                                                                idx,
                                                                              domProps:
                                                                                {
                                                                                  value:
                                                                                    value,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    value
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                    ],
                                                                    2
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-1 my-2 d-flex align-items-center",
                                                                },
                                                                [
                                                                  _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-sm btn-outline-danger",
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.deleteAdditionalEntry(
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fas fa-trash",
                                                                      }),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _vm.formFieldVisible(
                                            _vm.formInput.template.form.type,
                                            "button",
                                            "dropdown",
                                            "dropdown2",
                                            "dropdownMerge",
                                            "checkbox",
                                            "radio",
                                            "button",
                                            "datalist",
                                            "buttonMultiple"
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "form-row px-4",
                                                },
                                                [
                                                  _vm.formFieldVisible(
                                                    _vm.formInput.template.form
                                                      .type,
                                                    "button",
                                                    "dropdown",
                                                    "dropdown2",
                                                    "dropdownMerge",
                                                    "checkbox",
                                                    "radio",
                                                    "button",
                                                    "datalist",
                                                    "buttonMultiple"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-2",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "form-check-label",
                                                              attrs: {
                                                                for: "enableMergedMenu",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__enableMergedMenu"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enableMergedMenu,
                                                                expression:
                                                                  "formInput.template.form.enableMergedMenu",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              transform:
                                                                "scale(0.5)",
                                                              "margin-right":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              type: "checkbox",
                                                              id: "enableMergedMenu",
                                                            },
                                                            domProps: {
                                                              checked:
                                                                Array.isArray(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enableMergedMenu
                                                                )
                                                                  ? _vm._i(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form
                                                                        .enableMergedMenu,
                                                                      null
                                                                    ) > -1
                                                                  : _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .enableMergedMenu,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .enableMergedMenu,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "enableMergedMenu",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "enableMergedMenu",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "enableMergedMenu",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.formInput.template.form
                                                    .enableMergedMenu == true &&
                                                  _vm.formFieldVisible(
                                                    _vm.formInput.template.form
                                                      .type,
                                                    "button",
                                                    "dropdown",
                                                    "dropdown2",
                                                    "dropdownMerge",
                                                    "checkbox",
                                                    "radio",
                                                    "button",
                                                    "datalist",
                                                    "buttonMultiple"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-4",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "__mergedMenuParentItem"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .mergedMenuParentId,
                                                                  expression:
                                                                    "formInput.template.form.mergedMenuParentId",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form,
                                                                      "mergedMenuParentId",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                    selected:
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__NextNodeoptional"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._l(
                                                                _vm.allNodes,
                                                                function (
                                                                  node,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key: index,
                                                                      domProps:
                                                                        {
                                                                          value:
                                                                            node.nodeId,
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            node.title.substr(
                                                                              0,
                                                                              25
                                                                            )
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            node.subtitle
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.formInput.template.form
                                                    .enableMergedMenu == true &&
                                                  _vm.formFieldVisible(
                                                    _vm.formInput.template.form
                                                      .type,
                                                    "button",
                                                    "dropdown",
                                                    "dropdown2",
                                                    "dropdownMerge",
                                                    "checkbox",
                                                    "radio",
                                                    "button",
                                                    "datalist",
                                                    "buttonMultiple"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-4",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "__mappingField"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .mappingField,
                                                                  expression:
                                                                    "formInput.template.form.mappingField",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form,
                                                                      "mappingField",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                    selected:
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__UserVariableSelectOptional"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._l(
                                                                _vm.allVariables,
                                                                function (
                                                                  value,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key:
                                                                        "variable-" +
                                                                        index,
                                                                      domProps:
                                                                        {
                                                                          value:
                                                                            value,
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            value
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.formFieldVisible(
                                            _vm.formInput.template.form.type,
                                            "button",
                                            "dropdown",
                                            "dropdown2",
                                            "dropdownMerge",
                                            "checkbox",
                                            "radio",
                                            "button",
                                            "datalist",
                                            "buttonMultiple"
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "form-row px-4",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-2",
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label",
                                                          attrs: {
                                                            for: "enableHideBasedOnParent",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__enableHideBasedOnParent"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .template.form
                                                                .enableHideBasedOnParent,
                                                            expression:
                                                              "formInput.template.form.enableHideBasedOnParent",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        staticStyle: {
                                                          transform:
                                                            "scale(0.5)",
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          type: "checkbox",
                                                          id: "enableHideBasedOnParent",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.formInput
                                                                .template.form
                                                                .enableHideBasedOnParent
                                                            )
                                                              ? _vm._i(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enableHideBasedOnParent,
                                                                  null
                                                                ) > -1
                                                              : _vm.formInput
                                                                  .template.form
                                                                  .enableHideBasedOnParent,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.formInput
                                                                  .template.form
                                                                  .enableHideBasedOnParent,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "enableHideBasedOnParent",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "enableHideBasedOnParent",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.formInput
                                                                  .template
                                                                  .form,
                                                                "enableHideBasedOnParent",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm.formInput.template.form
                                                    .enableHideBasedOnParent ==
                                                  true
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-6",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__parentFieldReference"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .parentFieldReference,
                                                                  expression:
                                                                    "formInput.template.form.parentFieldReference",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form,
                                                                      "parentFieldReference",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                    selected:
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__NextNodeoptional"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._l(
                                                                _vm.allNodes,
                                                                function (
                                                                  node,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key: index,
                                                                      domProps:
                                                                        {
                                                                          value:
                                                                            node.nodeId,
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            node.title.substr(
                                                                              0,
                                                                              25
                                                                            )
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            node.subtitle
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "label",
                                                            {
                                                              attrs: {
                                                                tooltip:
                                                                  "遇到特定值才開啟隱藏",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__parentFieldReferenceSPValue"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .parentFieldSPValue,
                                                                expression:
                                                                  "formInput.template.form.parentFieldSPValue",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.formInput
                                                                  .template.form
                                                                  .parentFieldSPValue,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form,
                                                                  "parentFieldSPValue",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.formFieldVisible(
                                            _vm.formInput.template.form.type,
                                            "caption"
                                          )
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row px-4",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-5",
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(" type"),
                                                        ]),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .captionLevel,
                                                                expression:
                                                                  "formInput.template.form.captionLevel",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form,
                                                                  "captionLevel",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                  selected: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__UserTypeSelectOptional"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "h1",
                                                                },
                                                              },
                                                              [_vm._v("h1")]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "h2",
                                                                },
                                                              },
                                                              [_vm._v("h2")]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "h3",
                                                                },
                                                              },
                                                              [_vm._v("h3")]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "h4",
                                                                },
                                                              },
                                                              [_vm._v("h4")]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "h4",
                                                                },
                                                              },
                                                              [_vm._v("h4")]
                                                            ),
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "h6",
                                                                },
                                                              },
                                                              [_vm._v("h6")]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.formInput.template.form.enable == true &&
                                  _vm.formFieldVisible(
                                    _vm.formInput.template.form.type,
                                    "button",
                                    "dropdown",
                                    "dropdown2",
                                    "dropdownMerge",
                                    "text",
                                    "date",
                                    "hidden"
                                  )
                                    ? _c(
                                        "div",
                                        { staticClass: "border p-2 mb-2" },
                                        [
                                          _c("h5", [_vm._v("資料來源")]),
                                          _c("div", { staticClass: "row" }, [
                                            _c("div", { staticClass: "col" }, [
                                              _vm.formFieldVisible(
                                                _vm.formInput.template.form
                                                  .type,
                                                "button",
                                                "buttonMultiple",
                                                "dropdown",
                                                "dropdown2",
                                                "dropdownMerge",
                                                "checkbox",
                                                "datalist",
                                                "radio",
                                                "submit"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row px-4",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          class: {
                                                            "form-group col-8":
                                                              _vm.formInput
                                                                .template.form
                                                                .type ===
                                                              "checkbox",
                                                            "form-group col-4":
                                                              _vm.formInput
                                                                .template.form
                                                                .type !==
                                                              "checkbox",
                                                          },
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v("App ID"),
                                                          ]),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.formInput
                                                                    .template
                                                                    .form.appid,
                                                                expression:
                                                                  "formInput.template.form.appid",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              type: "hidden",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.formInput
                                                                  .template.form
                                                                  .appid,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form,
                                                                  "appid",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .appid,
                                                                  expression:
                                                                    "formInput.template.form.appid",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "form-control col-7",
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal =
                                                                      Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function (
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form,
                                                                      "appid",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.externals,
                                                              function (
                                                                external
                                                              ) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    domProps: {
                                                                      value:
                                                                        external.app_id,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        external.app_name
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c("div", { staticClass: "col" }, [
                                              _vm.formFieldVisible(
                                                _vm.formInput.template.form
                                                  .type,
                                                "button",
                                                "buttonMultiple",
                                                "dropdown",
                                                "dropdown2",
                                                "dropdownMerge",
                                                "checkbox",
                                                "datalist",
                                                "radio",
                                                "submit"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row px-4",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-6",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              "使用LINE USER 選取目前使用者"
                                                            ),
                                                          ]),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enableLineUserCheck,
                                                                expression:
                                                                  "formInput.template.form.enableLineUserCheck",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              transform:
                                                                "scale(0.5)",
                                                            },
                                                            attrs: {
                                                              type: "checkbox",
                                                            },
                                                            domProps: {
                                                              checked:
                                                                Array.isArray(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enableLineUserCheck
                                                                )
                                                                  ? _vm._i(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form
                                                                        .enableLineUserCheck,
                                                                      null
                                                                    ) > -1
                                                                  : _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .enableLineUserCheck,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .enableLineUserCheck,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "enableLineUserCheck",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "enableLineUserCheck",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "enableLineUserCheck",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("div", { staticClass: "col" }),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c("div", { staticClass: "col" }, [
                                              _vm.formFieldVisible(
                                                _vm.formInput.template.form
                                                  .type,
                                                "button",
                                                "buttonMultiple",
                                                "dropdown",
                                                "dropdown2",
                                                "dropdownMerge",
                                                "checkbox",
                                                "datalist",
                                                "radio",
                                                "submit"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row px-4",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-6",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              attrs: {
                                                                for: "enableSourceFilter",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "篩選來源條件"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enableSourceFilter,
                                                                expression:
                                                                  "formInput.template.form.enableSourceFilter",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            staticStyle: {
                                                              transform:
                                                                "scale(0.5)",
                                                            },
                                                            attrs: {
                                                              id: "enableSourceFilter",
                                                              type: "checkbox",
                                                            },
                                                            domProps: {
                                                              checked:
                                                                Array.isArray(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enableSourceFilter
                                                                )
                                                                  ? _vm._i(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form
                                                                        .enableSourceFilter,
                                                                      null
                                                                    ) > -1
                                                                  : _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .enableSourceFilter,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .enableSourceFilter,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "enableSourceFilter",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form,
                                                                        "enableSourceFilter",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "enableSourceFilter",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                            _c("div", { staticClass: "col" }),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col" },
                                              [
                                                _vm.formInput.template.form
                                                  .enableSourceFilter == true
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row mb-2 align-items-center",
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "col-2 my-2",
                                                        }),
                                                        _vm._m(3),
                                                        _vm._m(4),
                                                        _vm._m(5),
                                                        _vm._m(6),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-info m-1",
                                                            attrs: {
                                                              href: "javascript:void(0)",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addSourceFilter()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-plus text-info",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._l(
                                                  _vm.formInput.template.form
                                                    .sourceFilters,
                                                  function (entry, index) {
                                                    return _vm.formInput
                                                      .template.form
                                                      .enableSourceFilter ==
                                                      true
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key:
                                                              "entry-" + index,
                                                            staticClass:
                                                              "row mb-2",
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "col-2 my-2",
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-2 my-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            entry.parameter,
                                                                          expression:
                                                                            "entry.parameter",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      id:
                                                                        "sourcefilter-parameter-" +
                                                                        index,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            entry,
                                                                            "parameter",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "",
                                                                          selected:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__UserVariableSelectOptional"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._l(
                                                                      _vm.allVariables,
                                                                      function (
                                                                        value,
                                                                        idx
                                                                      ) {
                                                                        return _c(
                                                                          "option",
                                                                          {
                                                                            key:
                                                                              "parameter-" +
                                                                              idx,
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  value,
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  value
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row col-2 my-2",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        entry.value,
                                                                      expression:
                                                                        "entry.value",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    type: "text",
                                                                    id:
                                                                      "additional-value-" +
                                                                      index,
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      entry.value,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          entry,
                                                                          "value",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row col-2 my-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            entry.match,
                                                                          expression:
                                                                            "entry.match",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      name: "",
                                                                      id: "",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            entry,
                                                                            "match",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "",
                                                                          selected:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__UserVariableSelectOptional"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "equal",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "="
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "notequal",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "!="
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row col-2 my-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            entry.type,
                                                                          expression:
                                                                            "entry.type",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      name: "",
                                                                      id: "",
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            entry,
                                                                            "type",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "",
                                                                          selected:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__UserVariableSelectOptional"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "text",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "text"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "checkbox",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "checkbox"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "radio",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "radio"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-1 my-2 d-flex align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-sm btn-outline-danger",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteSourceFilter(
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fas fa-trash",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col px-10" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row px-4",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-3",
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label",
                                                            attrs: {
                                                              for: "enableCustomOptions",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "__enableCustomOptions"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.formInput
                                                                  .template.form
                                                                  .allowCustomOptions,
                                                              expression:
                                                                "formInput.template.form.allowCustomOptions",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          staticStyle: {
                                                            transform:
                                                              "scale(0.5)",
                                                          },
                                                          attrs: {
                                                            type: "checkbox",
                                                            id: "enableCustomOptions",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                _vm.formInput
                                                                  .template.form
                                                                  .allowCustomOptions
                                                              )
                                                                ? _vm._i(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form
                                                                      .allowCustomOptions,
                                                                    null
                                                                  ) > -1
                                                                : _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .allowCustomOptions,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .allowCustomOptions,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form,
                                                                      "allowCustomOptions",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm
                                                                        .formInput
                                                                        .template
                                                                        .form,
                                                                      "allowCustomOptions",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form,
                                                                  "allowCustomOptions",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm.formInput.template.form
                                                  .allowCustomOptions == true
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row mb-2 align-items-center",
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "col-2 my-2",
                                                        }),
                                                        _vm._m(7),
                                                        _vm._m(8),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-info m-1",
                                                            attrs: {
                                                              href: "javascript:void(0)",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addCustomOption()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-plus text-info",
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._l(
                                                  _vm.formInput.template.form
                                                    .customOptions,
                                                  function (option, index) {
                                                    return _vm.formInput
                                                      .template.form
                                                      .allowCustomOptions ==
                                                      true
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "row mb-2",
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "col-2 my-2",
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-4 my-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "col-9",
                                                                    attrs: {
                                                                      id:
                                                                        "custom-key-" +
                                                                        index,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        option.key
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    attrs: {
                                                                      id:
                                                                        "custom-edit-key-" +
                                                                        index,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.editCustomOption(
                                                                            "key",
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fas fa-pencil-alt cursor-pointer pl-1",
                                                                    }),
                                                                  ]
                                                                ),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        option.key,
                                                                      expression:
                                                                        "option.key",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control col-9 element-hide",
                                                                  attrs: {
                                                                    type: "text",
                                                                    id:
                                                                      "custom-input-key-" +
                                                                      index,
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      option.key,
                                                                  },
                                                                  on: {
                                                                    keypress:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.confirmChangeCustomOption(
                                                                          $event,
                                                                          "key",
                                                                          index
                                                                        )
                                                                      },
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          option,
                                                                          "key",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row col-5 my-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "col-9",
                                                                    attrs: {
                                                                      id:
                                                                        "custom-value-" +
                                                                        index,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        option.value
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    attrs: {
                                                                      id:
                                                                        "custom-edit-value-" +
                                                                        index,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.editCustomOption(
                                                                            "value",
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fas fa-pencil-alt cursor-pointer pl-1",
                                                                    }),
                                                                  ]
                                                                ),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        option.value,
                                                                      expression:
                                                                        "option.value",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control col-9 element-hide",
                                                                  attrs: {
                                                                    type: "text",
                                                                    id:
                                                                      "custom-input-value-" +
                                                                      index,
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      option.value,
                                                                  },
                                                                  on: {
                                                                    keypress:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.confirmChangeCustomOption(
                                                                          $event,
                                                                          "value",
                                                                          index
                                                                        )
                                                                      },
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          option,
                                                                          "value",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-1 my-2 d-flex align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-sm btn-outline-danger",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteCustomOption(
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fas fa-trash",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]),
                                          _vm._m(9),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.formInput.template.form.enable == true &&
                                  _vm.formFieldVisible(
                                    _vm.formInput.template.form.type,
                                    "all"
                                  )
                                    ? _c(
                                        "div",
                                        { staticClass: "border p-2 mb-2" },
                                        [
                                          _c("h5", [_vm._v("輸出")]),
                                          _c(
                                            "div",
                                            { staticClass: "container" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label",
                                                          attrs: {
                                                            for: "enableExportCheckbox",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__IsExport"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .template.form
                                                                .enableExport,
                                                            expression:
                                                              "formInput.template.form.enableExport",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        staticStyle: {
                                                          transform:
                                                            "scale(0.5)",
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          type: "checkbox",
                                                          id: "enableExportCheckbox",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.formInput
                                                                .template.form
                                                                .enableExport
                                                            )
                                                              ? _vm._i(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .enableExport,
                                                                  null
                                                                ) > -1
                                                              : _vm.formInput
                                                                  .template.form
                                                                  .enableExport,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.formInput
                                                                  .template.form
                                                                  .enableExport,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "enableExport",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "enableExport",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.formInput
                                                                  .template
                                                                  .form,
                                                                "enableExport",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm.formFieldVisible(
                                                _vm.formInput.template.form
                                                  .type,
                                                "all"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row px-4",
                                                    },
                                                    [
                                                      _vm.accountType ==
                                                        "line" &&
                                                      _vm.formInput.template
                                                        .form.type !== "submit"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-4",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-danger",
                                                                  },
                                                                  [_vm._v("*")]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__variable"
                                                                      )
                                                                    )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "text-info m-1",
                                                                  attrs: {
                                                                    href: "javascript:void(0)",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showExistVariable(
                                                                          "UserReply"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fas fa-edit text-info",
                                                                  }),
                                                                ]
                                                              ),
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .formInput
                                                                          .template
                                                                          .form
                                                                          .variable,
                                                                      expression:
                                                                        "formInput.template.form.variable",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal =
                                                                          Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function (
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formInput
                                                                            .template
                                                                            .form,
                                                                          "variable",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "",
                                                                        disabled:
                                                                          "",
                                                                        selected:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__UserVariableSelectOptional"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._l(
                                                                    _vm.allVariables,
                                                                    function (
                                                                      value,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            "variable-" +
                                                                            index,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                value,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                value
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label",
                                                          attrs: {
                                                            for: "autoMaxPlus1Checkbox",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__AutoMaxPlus1"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .template.form
                                                                .autoMaxPlus1,
                                                            expression:
                                                              "formInput.template.form.autoMaxPlus1",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        staticStyle: {
                                                          transform:
                                                            "scale(0.5)",
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          type: "checkbox",
                                                          id: "autoMaxPlus1Checkbox",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.formInput
                                                                .template.form
                                                                .autoMaxPlus1
                                                            )
                                                              ? _vm._i(
                                                                  _vm.formInput
                                                                    .template
                                                                    .form
                                                                    .autoMaxPlus1,
                                                                  null
                                                                ) > -1
                                                              : _vm.formInput
                                                                  .template.form
                                                                  .autoMaxPlus1,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.formInput
                                                                  .template.form
                                                                  .autoMaxPlus1,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "autoMaxPlus1",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .template
                                                                      .form,
                                                                    "autoMaxPlus1",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.formInput
                                                                  .template
                                                                  .form,
                                                                "autoMaxPlus1",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _c("label", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__ExportType"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.formInput
                                                                  .template.form
                                                                  .export.type,
                                                              expression:
                                                                "formInput.template.form.export.type",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                _vm.formInput
                                                                  .template.form
                                                                  .export,
                                                                "type",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__pleaseEnter"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "string",
                                                              },
                                                            },
                                                            [_vm._v("string")]
                                                          ),
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "array",
                                                              },
                                                            },
                                                            [_vm._v("array")]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        staticStyle: { width: "100%" },
                        attrs: {
                          id: "addNode",
                          type: "button",
                          "data-toggle": "modal",
                          "data-translate": "_confirm.node",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.submitNode()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("__determine")) + " ")]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
      _c("create-variable-modal", { on: { newVariable: _vm.newVariable } }),
      _c("exist-variable-modal", {
        attrs: { allVariables: _vm.variables },
        on: {
          addVariable: _vm.addVariable,
          removeVariable: _vm.removeVariable,
        },
      }),
      _c("var-form-modal", {
        attrs: { allVariables: _vm.variables, formObject: _vm.formObjectModel },
        on: { saveForm: _vm.saveForm },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 my-2" }, [
      _c("p", { staticClass: "col-9 my-2" }, [_c("b", [_vm._v("Parameter")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "col-4 my-2" }, [
      _c("b", [_vm._v("Variable")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "col-2 my-2" }, [
      _c("b", [_vm._v("Parameter")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "col-2 my-2" }, [_c("b", [_vm._v("Value")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "col-2 my-2" }, [
      _c("b", [_vm._v("Condition")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "col-1 my-2" }, [_c("b", [_vm._v("Type")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4 my-2" }, [
      _c("p", { staticClass: "col-9 my-2" }, [_c("b", [_vm._v("Key")])]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "col-4 my-2" }, [_c("b", [_vm._v("Value")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }),
      _c("div", { staticClass: "col" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }