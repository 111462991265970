export default {
    // Login
    __userName: 'ユーザー名',
    __password: 'パスワード',
    __loginButton: 'ログイン',
    __loginFailed: 'ログインに失敗しました',

    //account
    __dataMissing: '必須項目に値を入力してください',
    __CopiedToClipboard: 'クリップボードにコピーしました',
    __line: 'LINE',
    __wechat: 'WeChat',
    __facebook: 'Facebook',
    __instagram: 'Instagram',

    __requiredItem: '必須項目',
    __home: 'ホーム',
    __dialogsetup: 'シナリオ設定',
    __formsetup: 'フォーム設定',
    __richMenu: 'リッチメニュー',
    __segment: 'ユーザーセグメント',
    __richAllUser: '全てのユーザー(デフォルト)',
    __haveDefaultMenu: 'デフォルトのrichMenuは存在しています',
    __pushMessage: 'シナリオ送信',
    __exportData: 'データエクスポート',
    __externalApiConfig: '連携情報の設定',
    __appsConfig: 'Appの設定',
    __generalSetup: '一般設定',
    __metaUtils: 'ユーティリティツール',
    __tagList: 'タグリスト',
    __iconSetting: 'Icon設定',
    __statisc: '統計分析',
    __node: '会話ノード',
    __Signout: 'ログアウト',
    __Switchaccount: 'アカウントを切り替える',
    __Createnewlink: '連携アカウントを追加',
    __name:'名前',
    __username: 'アカウント名',
    __accountId: 'アカウントID',
    __ConnectionType: '連携SNS',
    __channelSecret_MsgAPI:'チャネルシークレット (Messaging API)',
    __channelAccessToken_MsgAPI:'チャネルアクセストークン (Messaging API)',
    __channelId_LineLogin:'チャネルID (LINE Login)',
    __channelSecret_LineLogin:'チャネルシークレット (LINE Login)',
    __Accounticon: 'プロフィール画像',
    __changeAccountImage: 'アカウント画像変更',
    __changeAccountName: 'アカウント名変更',
    __establishconnection: '連携',
    __accountClone: 'アカウントをコピーする',
    __addedsuccessfully: '正常に追加されました',
    __FbStartMessage: '開始メッセージ',
    __FbGreetingMessage: 'あいさつ文',
    __Anewaccounthasbeensuccessfullycreated: '新しいアカウントが作成されました',
    __Addfailed: '追加できませんでした',
    __PlzPutKeyWord: 'キーワードを入力してください',
    __KeyWordExist: 'このキーワードはすでに存在します',
    __oopsSomethingwentwrong: 'エラーが発生しました',
    __somethingwentwrongindeletemenu: 'RichMenu削除失敗しました',
    __Numberofexistingnodes: '現在の会話ノード数総計',
    __Followers: 'フォロワー',
    __Triggerers:"觸發用戶",
    __Trackusersnow: '現在のフォロワー数総計',
    __Triggerednow: 'トリガーしたユーザー',
    __Searchusersnow: 'サーチのフォロワー数総計',
    __Startingdate: '開始日',
    __Enddate: '終了日',
    __Export: '出力',
    __Outputfailed: '出力に失敗しました',
    __Pleasefillinallfields: 'すべての項目を記入してください',
    __ImageSizeIncorrect: '画像サイズエラー',
    __ImageSizeToobig: '写真が1MBを超えている',
    __TabFieldseIncorrect: '切り替えるメニューの項目を記入してください',
    __CreateCSVfile: 'CSVファイルを作成',
    __TheCSVfilewassuccessfullyaddedanddownloaded: 'CSVファイルが正常に作成、ダウンロードされました',
    __Outputfailed: '出力に失敗しました',
    __edit: '編集する',
    __Anewconversationhasbeensuccessfullyestablished: '新しいシナリオが正常に作成されました',
    __Theappointmentschedulehasbeensuccessfullycreated: '予約スケジュールが正常に作成されました',
    __setup: 'セットアップ',
    __Areyousureyouwanttodeleteit: '削除してもよろしいですか',
    __Thesystemwillnotbeabletoretrieveyourdata: '削除したデータは復元できません',
    __YesIwanttodelete: 'はい、削除します',
    __cancel: 'キャンセル',
    __successfullydeleted: '正常に削除されました',
    __failedtodelete: '削除できませんでした',
    __Appointmentscheduling: '予約',
    __Yourdatahasbeensuccessfullydeleted: 'データは正常に削除されました',
    __Appointment: '送信日時',
    __Pleaseselecttheconversationyouwanttosend: '送信するシナリオを選択してください:',
    __Nextstep: '次へ',
    __sendingmethod: '送信先',
    __Userclassification: 'ユーザーセグメント一覧',
    __personal: 'ユーザごとに選択',
    __Pleasechoosetosendto: '送信先を選択してください',
    __Previous: '前',
    __determine: 'OK',
    __Pleaseselectaconversation: '会話を選択してください',
    __Pleaseenterthetime: '時間を入力してください',
    __Thenumberofsendingtargetscannotbe0: '送信ターゲットの数を0にすることはできません',
    __Sendrecord: '送信履歴',
    __SendID: '送信ID',
    __Deliverydate: '送信予定日時',
    __Establishmenttime: '実送信日時',
    __MultiCondition: '複数の条件',
    __Customgroup: '手動設定',
    __FollowAccountTime: '友達として追加された日付',
    __Selectcategoryconditions: '分類基準を選択する',
    __Selectuser: 'ユーザーを選択',
    __Searchusers: 'ユーザーを検索',
    __Selectall: 'すべて選択',
    __DefaultUserSegment: 'デフォルトのユーザー クラス',
    __webhookUrl: 'Webhook URL',
    __Keepsessionrecords: '会話履歴の保持期間',
    __Sessionrecord: '会話履歴の保存期間。期間を過ぎた会話履歴は自動的に削除されます',
    __Deleteuserrelatedsettings: 'ユーザー関連のデータを削除する',
    __Imagechangeerror: '申し訳ありません。エラーが発生しました。再び画像を選択してください',
    __Clearallusagerecordsthesystemwillonly: '会話履歴、ユーザセグメントが削除されます。テスト運用等の後に、アカウントを残したまま履歴を消す際にご使用ください',
    __successfullydeleted: '正常に削除されました',
    __Systemuserinformationhasbeencleared: 'システムユーザー情報が消去されました',
    __failedtodelete: '削除できませんでした',
    __Manualsending: '手動送信',
    __Userjoin: 'フォローイベント発生',
    __Keyword: 'キーワード受信',
    __LiffScenario: 'Liffシナリオ',
    __FormFilter: 'フォームフィルター',
    __Errormessage: 'エラー発生',
    __Editsuccessfullyaccessed: '編集が完了しました',
    __Theerrormessageoruserjoinmessagealreadyexists: 'エラーメッセージまたはフォローイベントメッセージは既に存在します',
    __Theerrormessagealreadyexists: 'エラーメッセージはすでに存在します',
    __Userjoinmessagealreadyexists: 'フォローイベントメッセージはすでに存在します',
    __Copycurrenttarget: 'コピーして作成',
    __Createacopyofthisconversationwiththenode: 'このシナリオのコピーを作成します',
    __Add: '作成',
    __cancel: 'キャンセル',
    __Anewcopyhasbeencreated: '新しいコピーが作成されました',
    __Areyousureyouwanttodeleteit: '削除してもよろしいですか',
    __SendID: '送信ID',
    __Conversationname: 'シナリオ名',
    __MultiDelete: 'バッチ削除',
    __Pushtarget: '送信人数',
    __sendstatus: 'ステータス',
    __Scheduleddeliverydate: '送信予定日時',
    __1day: '1日',
    __10days: '10日',
    __30days: '30日',
    __60days: '60日',
    __90days: '90日',
    __180days: '180日',
    __Theappointmentschedulehasbeensuccessfullycreated: '予約スケジュールが正常に作成されました',
    __Anewcategoryhasbeensuccessfullycreated: '新しいカテゴリが作成されました',
    __blockade: 'アンフォロー',
    __Active: 'アクティブ',
    __Deliverytype: '送信タイミング',
    __Pleaseselectthedestination: '送信先を選択してください',
    __Cancelall: 'すべてキャンセル',
    __Nolabelsavailable: '使用できるラベルがありません',
    __Novariablesavailable: '利用可能なユーザー変数はありません',
    __Selectuser: 'ユーザーを選択',
    __SelectInterval: '間隔を選択',
    __SelectCvParam: 'CVパラメーターを選択します',
    __CvParam: 'CVパラメーター',
    __ServerPushTime: 'サーバープッシュ',
    __Nousersfound: 'ユーザが見つかりませんでした',
    __createSegmentInputError: '入力エラー',
    __createSegmentNoSelectTags: 'タグが選択されていません',
    __createSegmentNoSelectInterval: '間隔が選択されていません',
    __createSegmentNoCvValue: 'CV値なし.',
    __Userlist: 'ユーザー一覧',
    __segmentcondition: '値がある',
    __segmentnocondition: '値がない',
    __segmentnoexist: '不存在',
    __CategoryName: 'セグメント名',
    __Classificationrules: 'セグメント化ルール',
    __Numberofpeopleincategory: 'セグメント人数',
    __segmentedUsers: 'セグメント内ユーザー',
    __source: 'ソース',
    __value: '値',
    __Sendconversation: 'シナリオ送信',
    __EditRichMenu: '編集リッチメニュー',
    __FlexReplaceTxtHint: '代替テキスト',
    __userstatuse: 'ステータス',
    __Followtime: 'フォロー日時',
    __Labelname: 'タグ名',
    __Chooselabelcolor: 'タグの色を選択してください',
    __NextNodeoptional: '次の会話ノード',
    __Addlabel: 'ラベルを追加',
    __AddUserIcon: 'Iconの追加',
    __AddVariable: '変数を追加',
    __AddRichMenu: 'リッチメニュー追加',
    __EditRichMenu: '編集リッチメニュー',
    __NextScenariooptional: '送信シナリオ',
    __TagValueSelectOptional: 'Tag値',
    __UserVariableSelectOptional: 'Variable値',
    __UserTypeSelectOptional: 'type值',
    __Keyoptional: 'Key値',
    __Valueoptional: 'Value値',
    __Titlebold: 'タイトル',
    __flexMsgTitle: 'FlexMessageタイトル',
    __richMsgTitle: 'RichMessageタイトル',
    __image: '画像',
    __Subtitlebold: 'サブタイトル',
    __Textcontent: 'テキスト',
    __Texture: 'スタンプ',
    __MenuName: 'Menu名称',
    __MenuId: 'MenuId',
    __Button: 'ボタン',
    __Jump: '連続送信',
    __Sendbyappointment: 'プッシュ予約',
    __RemoveVariable: '変数の除去',
    __Externalapisetting: '外部APIと連携設定',
    __Appssetting: 'アプリ設定',
    __ImageVideoMessage: '画像・動画メッセージ',
    __ImageUrl: '画像の URL',
    __VideoUrl: '動画の URL',
    __urltitle: '画像タイトル',
    __colour: '色',
    __Buttondirection: 'ボタンの方向',
    __Horizontal: '横一列',
    __Vertical: '縦一列',
    __Buttoncolor: 'ボタンの色 ＃000000',
    __fontcolor: 'フォント色',
    __Fontwhite: '白字',
    __Fontblack: '黒字',
    __urllinkoptional: 'URLリンク',
    __FlexReplaceTxtHint: '代替テキスト',
    __userstatuse: 'ステータス',
    __Followtime: 'フォロー日時',
    __Labelname: 'ラベル名',
    __IconName: 'Icon名',
    __Variablename: '変数名',
    __RichMenuname: 'リッチメニュー名',
    __RichMenuText: 'リッチメニュー文字',
    __Chooselabelcolor: 'ラベルの色を選択してください',
    __Thenewlabelmustbereusedafterthestoragenode: '新しいラベルは保存後に再利用することができます',
    __Createnewlabel: '新しいラベルを作成',
    __CreateNewUserIcon: '新しいIconを作成する',
    __Createnewvariable: '新しい変数を作成',
    __CreatenewRichMenu: '新しいリッチメニューを作成',
    __saveRichMenu: 'リッチメニューを保存',
    __Pleaseenteranameandchooseacolor: '名前と色を入力してください',
    __PleaseEnterAllRequiredField: '必須フィールドをすべて入力してください',
    __Settingsnotyetsaved: '設定が保存されていません',
    __Thesystemwillnotbeabletokeeptheunsaveddataareyousureyouwanttoleavethecurrentpage: 'システムは保存されていないデータを保持できません。現在のページから移動してもよろしいですか？',
    __updatecompleted: '更新が完了しました',
    __Abouttojumpbacktothedialogsettingpage: 'シナリオ一覧にジャンプしようとしています',
    __Lv1startingpointistheonlyitemthatmustbeset: 'Lv1はシナリオの起点となるため、1つの会話ノードのみ保存可能です',
    __cannotSaveScenarioWithoutLv1Node: 'Lv1に会話ノードがない状態では保存できません',
    __Custom: '手動設定',
    __packageID: 'パッケージID',
    __stickerID: 'スタンプID',
    __Linktonextnode: '次の会話ノード',
    __WhenusingFlexMessageyoucanuse: 'Flex Messageを使用する場合は、',
    __Viewtheeditedcontentinthewindowinstantly: 'にて編集することが可能です。',
    __Sentconversation: '送信するシナリオ',
    __Rear: '後',
    __Clickordragtoupload: 'クリックまたはドラッグしてアップロード',
    __characterinput: 'にスペース以外の適切な文字を入力してください。',
    __urlmustbeavalidlink: 'URLは有効なリンクである必要があります',
    __Areyousureyouwanttodeletethelabel: 'ラベルを削除してもよろしいですか',
    __AreyousureyouwanttodeletetheVariable: 'ユーザー変数を削除してもよろしいですか',
    __Thesystemwillnotbeabletoretrieveyourdata: 'システムはデータを取得できなくなります',
    __editLabel: 'タグを編集',
    __saveEditTag: 'タグを保存',
    __saveUserIcon: 'Icon保存',
    __editUserIcon: 'Icon編集',
    __deleteUserIcon: 'Iconの削除',
    __Triggerpoint: '送信トリガー',
    __Numberofnodes: '会話ノード数',
    __Editdateandtime: '最終編集日時',
    __createNewNode: '新しいノードを作成する',
    __label: 'ラベル',
    __variable: 'ユーザー変数',
    __accountVariable: 'アカウント変数',
    __systemReply: '自動返信',
    __UnabletodeleteLevellabel: 'レベルラベルは削除できません',
    __days: '曰',
    __hours: '時間',
    __minutes: '分',
    __StickerList: '利用可能なスタンプの一覧はこちら',
    __cancelupload: 'アップロードをキャンセルしてもよろしいですか？',
    __getFollowers: 'フォロワーを更新',
    __getFollowersConfirmMessage: 'フォロワー更新はLINE公式アカウントタイプが未認証アカウントでは実行できません。実行しますか？',
    __getFollowersConfirm: '実行する',
    __getFollowersCancel: 'キャンセル',
    __updateFollowers: 'フォロワーをSNSより取得中です。この処理には数分ほどかかる場合があります。処理はバックグラウンドで実行されるため、再度ページを開きなおしてください。',
    __authExpired: 'セッションがタイムアウトしました',
    __pleaseLogin: 'もう一度ログインしてください',
    __TagName: 'タグ名',
    __TagColor: 'タグ色',
    __nodeNameConditionUrl: '条件（URL）',
    __nodeNameConditionSegment: '条件（セグメント）',

    // UserIcon
    __newIconCreated: 'Iconが作成されました',

    // TagList
    __newTagCreated: '新しいタグを作成しました',

    // PushMessage
    __statusPending: '送信待ち',
    __statusDone: '送信完了',
    __statusIssue: '送信失敗',

    // ExportData
    __invalidPeriodInputed: '不正な期間が入力されています',
    __noData: '出力データがありませんでした',
    __csvHeaderUserId: 'ユーザーID',
    __csvHeaderUserName: 'ユーザー名',
    __csvHeaderUserPicture: 'ユーザーサムネイル画像',
    __csvHeaderAccountType: '連携SNS',
    __csvHeaderAccountStatus: 'アカウント状態',
    __csvHeaderNodeId: 'ノードID',
    __csvHeaderTags: 'タグ',
    __csvHeaderFollowTime: 'フォロー日時',
    __csvHeaderAccessTime: 'アクセス日時',
    __csvHeaderCreateTime: '作成日時',
    __csvBodyAccountTypeLine: 'LINE',
    __csvBodyAccountTypeWeChat: 'WeChat',
    __csvBodyAccountStatusEnabled: 'フォロー中',
    __csvBodyAccountStatusUnfollow: 'アンフォロー',

    // Story
    __keywords: 'キーワード',

    //CreateEditStory（ストーリー編集ダイアログ）
    __keywordInput: 'キーワードを入力',

    // CreateEditNode（ノード編集ダイアログ）
    __flexmessageSize: 'メッセージサイズ',
    __flexmessageSizeChange: 'Flex Messageサイズ設定',
    __setSize: 'サイズを選ぶ',
    __modalTitleNodeId: 'ノードID',
    __Condition: '条件ノード',
    __Message: '会話ノード',
    __Titlebold: 'タイトル',
    __image: '画像',
    __Subtitlebold: 'サブタイトル',
    __Textcontent: 'テキスト',
    __Texture: 'スタンプ',
    __Button: 'ボタン',
    __Jump: '連続送信',
    __Sendbyappointment: 'プッシュ予約',
    __urlPlaceHolder: 'http://... or https://...',
    __urllinkoptional: 'タップ時のリンク先URL',
    __colour: '色',
    __Buttontext: 'ボタンのテキスト',
    __Buttondirection: 'ボタンの方向',
    __Horizontal: '横一列',
    __Vertical: '縦一列',
    __Buttoncolor: 'ボタン色',
    __buttonColorGreen: '緑',
    __buttonColorBlue: '青',
    __buttonColorRed: '赤',
    __buttonColorYellow: '黄',
    __buttonColorGray: 'グレー',
    __buttonColorBlack: '黒',
    __setHexColorCode: '16進数カラーコードで指定',
    __fontcolor: 'フォント色',
    __Fontwhite: '白文字',
    __Fontblack: '黒文字',
    __labelSettings: 'タグ設定',
    __TagSelectOptional: 'タグ名',
    __UserIconSelectOptional: 'Icon名',
    __CrosOptional: 'CROS項目',
    __CrosTypeOptional: 'CROSタイプ',
    __TagValueSelectOptional: 'タグvalue',
    __buttonTappedAction: 'ボタンタップ時の動作',
    __jumpToSpecificUrl: '指定したURLへ移動',
    __jumpToSpecificScenario: '指定したシナリオへ移動',
    __jumpToSpecificNode: '指定したノードへ移動',
    __NextNodeoptional: '会話ノード',
    __NextScenariooptional: '送信シナリオ',
    __conditionUrl: '条件とするURL',
    __branchSettings: '分岐設定',
    __whenMatching: 'と一致するとき',
    __jumpTo: 'へ移動',
    __invalidScheduleTimeError: '不正な値が入力されています',
    __flexMessage: 'Flex Message',
    __template: 'テンプレート',
    __templateUnion: 'テンプレートの組み合わせ',
    __genericTemplate: '汎用テンプレート',
    __stickerRefUrl: 'https://developers.line.biz/ja/docs/messaging-api/sticker-list/',
    __ecsiteBox: 'Penglue連携',
    __chichatcatBox: 'ChiChatCat連携',
    __encryption: '暗号化',
    __saveTime: '時間保存',
    //RichMenu
    __background: '背景',
    __selectImage: '画像を選択',
    __richMsgLayout: 'テンプレート',
    __workTime: "作業期間",
    __richMsgAliasLayout: 'メニューの切り替えを使用',
    __action: '動作',
    __menuId: 'メニューID',
    __menuName: 'メニュー名',
    __switchMenuName: '切り替えるメニュー名',
    __xAxis: 'X軸',
    __yAxis: 'Y軸',
    __tabWidth: '幅',
    __tabHeight: '高さ',
    __open_menu: 'デフォルトでメニューを開く',

    // User Reply
    __Userreply: 'ユーザーの返信',
    __UserreplyVariableType: '変数カテゴリー',
    __UserreplyVariableType_Eng: '英語',
    __UserreplyVariableType_Num: '番号',
    __UserreplyVariableType_Len: '単語数',
    __UserreplyVariableType_RegExp: '正規表現',
    __UserreplyVariableType_Phone: '電話番号',
    __UserreplyVariableType_Post: '郵便番号',
    __UserreplyVariableType_City: '郡名',
    __VariableFormatValidateSuccess: '検証に成功しました',
    __VariableFormatValidateFailed: '検証に失敗しました',

    // ExternalApiConfig
    __EndPoint: 'API URL',
    __ClientId: 'Client ID',
    __ApiLink: '連携',
    __ExternalapiEnable: '外部APIと連携しま',
    __ExternalapiName: '接携されたAPI',
    __ApiResponseSuccess: 'API接続が成功しました',
    __ApiResponseFailed: 'API接続に失敗しました',
    __OrderIdReplaceEnable: 'OrderIdテキストの置換が有効になっています',
    __SelectErrorCode: 'エラーコードを選択',
    __isRequire: '必須',
    __Type: 'タイプ',
    __setType: 'タイプを設定',
    __setKey: 'キーを設定',
    __setValue: '値を設定',
    __FilterNodeEnable: '他のノード条件でフィルタリングしますか',
    __setFilterNode: 'フィルターノードを設定',

    // AppsApiConfig
    __Target: '接携されたアプリ名',
    __appsSettingEnable: 'アプリ設定',
    __webhookUrl: 'Webhook URL',

    // FormFilterConfig
    __FilterType: 'フィルタータイプ',
    __FilterField: 'フィルターフィールド',
    __ShowField: '表示フィールド',
    __filterTypeSelectOptional: 'フィルタータイプを選択',
    __typeSelect: 'タイプを選択',
    __dateType: '日付',
    __dateRange: '日付範囲',
    __dataSource:'資料來源',

    // Pagination
    __paginationText: '{count}件のうち{from}〜{to}件を表示|{count} 件|1件',

    // Text Message
    __Emoji: '絵文字',
    __EmojiProdId: 'Product ID',
    __EmojiId: '絵文字 ID',
    __QuickReply: 'クイックリプライ',
    __QuickReplyAction: 'アクション',
    __QuickReplyPostback: 'ポストバックアクション',
    __QuickReplyMessage: 'メッセージアクション',
    __QuickReplyUri: 'URIアクション',
    __QuickReplyTimepicker: '日時選択アクション',

    //datetimepicker
    __timepickerText: '時間と日付け',
    __timepickerVariable: '時間変数',
    __timepickerInitialTime: '初回日時',
    __timepickerMinTime: '最小時間',
    __timepickerMaxTime: '最大時間',
    __timepickerStartTime: '開始時間',
    __timepickerEndTime: '終了時間',
    __FbtagNormal: "一般情報",
    __FbtagAccountUpdate: "アカウントの更新",
    __FbtagConfirmEvenUpdate: "イベント更新リマインダー",
    __FbtagPurchaseUpdate: "購買行動の最新情報",
    __DataRange: "期間",
    __DatatimeRange: "日付と時刻の範囲",
    __NumberRange: "値の範囲",
    __LessThanNumber: "値未満",
    __GreaterThanNumber: "値より大きい",
    __ExactlyMatch: "完全一致",
    __FuzzyMatch: "部分一致",
    __Start: "始める",
    __End: "終了",


    //varForm
    __Form: '表單',

    // datetime_trigger
    __DateTimeTrigger: "トリガー時間帯",
    __DateTimeTriggerEnable: "トリガー時間帯を使う",
    __DateTimeTriggerStart: "開始時間",
    __DateTimeTriggerEnd: "終了時間",

    // userlist
    __userlist_filter:"フィルター",
    __userlist_matchAll:"完全一致",
    __userlist_matchAny:"部分一致",
    __userlist_notMatchAny:"どれにも一致しません",
    __userlist_notMatchAll:"全部一致しません",
    __userlist_addNewFilter:"+條件を追加",
    __userlist_filterType:"フィルターの種類",
    __userlist_userTag:"ユーザータグ",
    __userlist_userReply:"ユーザーの返信",
    __userlist_userFirst:"最初のやりとりした時間",
    __userlist_userLast:"最後のやりとりした時間",
    __userlist_userSegment:"ユーザーセグメント",
    __userlist_userBlock:"ブラックリスト",
    __userlist_userBlockWhite:"ホワイトリスト",
    __userlist_query:"検索",
    __userlist_reset:"リセット",
    __userlist_tagAdd:"タグを追加",
    __userlist_noValue:"valueなし",
    __userlist_addTag:"タグを追加",
    __userlist_tagRemove:"タグを取り除く",
    __userlist_removeTag:"タグを取り除く",
    __userlist_unblock:"ブラックリストから削除",
    __userlist_matchType:"比較の仕方",
    __userlist_delete:"削除",
    __userlist_hasValue:"valueあり",
    __userlist_notExist:"存在しない",
    __userlist_segmentName:"ユーザーセグメントの名称",
    __userlist_lastUpdateTime:"最後アップデート時間",
    __userlist_timeStart:"開始時間",
    __userList_timeEnd:"終了時間",
    __userList_noTagsSelected:"まだタグが選択されていません",
    __userList_noUserOrAllSelected:"ユーザーがまだ選択されていません",

    //Utils
    __autoReplyComments: "投稿にコメントする",
    __shortLink: "ショットリンクを開く",
    __autoReplyLiveMention: "ストーリーでメンションする",
    __autoReplyMedia: "ビデオにコメントする",
    __utilsName: "ユーティリティツールの名前",
    __utilsId: "ユーティリティツールId",
    __utilsType: "タイプ",
    __utilsStatus: "ステータス",
    __workTime: "作業期間",
    __instagramPost: "Instagramの投稿",
    __neetSelectPost: "投稿を選択してください",
    __needTypeName: "ツールの種類を選択してください",
    __startScrnario: "シナリオを開始",
    __defaultReply: "デフォルトの返信",
    __createDefaultReply: "デフォルトの返信を追加",
    __conditionReply: "条件に応じた返信",
    __generateShortLink: "短縮URLを生成",
    __generateQRCode: "QRコードを生成",
    __createConditionReply: "条件に応じた返信を追加",
    __useAutoReply: "公開とユーティリティメッセージ返信を使用する",
    __addConditionField: "トリガー条件を追加",
    __setCondition: "条件の設定",
    __messageContent: "メッセージ内容",
    __friendsCount: "友達の数",
    __conditionContent: "条件内容の設定",
    __publicMessage: "公開メッセージの内容",
    __secretMessage: "ユーティリティメッセージの選択",
    __selectedScenario: "送信するシナリオを選択",
    __submit: "確定",
    __exactlyMatch: "完全一致",
    __fuzzyMatch: "部分一致",
    __shortVideo: "ショートビデオ",
    __pleaseEnterName: "ツール名を入力してください",
    __pleaseEnterMediaId: "投稿を選択してください",
    __pleaseEnterScenario: "シナリオを選択してください",
    __pleaseEnterDefaultField: "デフォルトの返信を入力してください",
    __pleaseEnterConditionFields: "条件に応じた返信を入力してください",
    __pleaseEnterConditionTrigger: "条件に応じた返信のトリガー条件を入力してください",
    __Anewuitlhasbeensuccessfullycreated: '新しいカテゴリが作成されました',

    // LIFF表單
    __label: 'ラベル (label)',
    __caption: 'キャプション (caption)',
    __input: '入力 (input)',
    __dropdown: 'ドロップダウン (dropdown)',
    __dropdown2:'ドロップダウン2 (dropdown2)',
    __dropdownMerge:'ドロップダウン結合 (dropdownMerge)',
    __text: 'テキスト入力フィールド (text)',
    __textarea: 'テキストエリア (textarea)',
    __buttonSingle: '單選ボタン (button)',
    __buttonMultiple: '多選ボタン (multiple button)',
    __date: '日付選択フィールド (date)',
    __time: '時間選択フィールド (time)',
    __datetime_local: '日付と時間選択フィールド (datetime-local)',
    __email: 'メール入力フィールド (email)',
    __number: '数値入力フィールド (number)',
    __tel: '電話番号入力フィールド (tel)',
    __url: 'URL入力フィールド (url)',
    __search: '検索フィールド (search)',
    __color: 'カラー選択フィールド (color)',
    __range: '範囲選択フィールド (range)',
    __file: 'ファイル選択フィールド (file)',
    __multiple: '複数ファイル選択フィールド (multiple)',
    __checkbox: 'チェックボックス (checkbox)',
    __radio: 'ラジオボタン (radio)',
    __hidden: '非表示入力フィールド (hidden)',
    __month: '月選択フィールド (month)',
    __week: '週選択フィールド (week)',
    __submitbutton: '送信ボタン (submit)',
    __reset: 'リセットボタン (reset)',
    __datalist: 'データリスト (datalist)',

    __variable: '変数',
    __titlename: 'タイトル',
    __requiredField: '必須かどうか',
    __enable: '有効',
    __enableFilterNode: 'フィルターを有効にする',
    __multipleSubformField: '複数入力フォームフィールド',
    __subformlookupFiled: 'サブフォームの参照フィールド',
    __permission: '権限',
    __defaultValue: 'デフォルト値',
    __defaultToday: '預設今天日期',
    __validationRules: '検証ルール',
    __enableHideBasedOnParent: '親に基づいて非表示にする',
    __parentFieldReference: '親フィールドの参照',
    __parentFieldReferenceSPValue:'特定值',
    __allowAddValue: '値の追加を許可する',
    __allowMultiple: '複数入力を有効にする',
    __enableSubForm:'サブフォームを有効にする',
    __enterSubformId:'サブフォームIDを入力してください',
    __enterSubformName:'サブフォーム名を入力してください',
    __enterSubformMapping:'多選択項目対応サブフォーム',
    __mergeCard:'隣接するノードを基にフロントエンドのカードを統合する',
    __enableOrder:'順序を有効にする',
    __ordertype:'順序タイプ',
    __orderfield:'順序フィールド',
    __AscendingOrder:'昇順',
    __DescendingOrder:'降順',
    __enableCustomOptions:'カスタムオプションを有効にする',
    __enableAdditionalEntryField:'追加エントリーフィールド',
    __enableAuxiliarySelection: '補助選擇を有効にする',
    __auxiliarySelectionNode: '補助選擇ノードを設定してください',
    __auxiliarySelectionField: '補助選擇欄位',
    __enableMergedMenu: '結合メニューを有効にする',
    __mergedMenuParentItem: '結合メニューの親項目',
    __mappingField: '対応フィールド',
    __menuSettings: 'メニュー設定',
    __editMenuSettings: 'メニュー設定を編集',
    __menuName: 'メニュー名',
    __menuUrl: 'メニューURL',
    __save: '保存',
    __cancel: 'キャンセル',
    __Settingsnotyetsaved: '設定がまだ保存されていません',
    __Thesystemwillnotbeabletokeeptheunsaveddataareyousureyouwanttoleavethecurrentpage: 'システムは未保存のデータを保持できません。本当に現在のページを離れますか？',
    __authExpired: '認証が期限切れ',
    __pleaseLogin: 'ログインしてください',
    __cannotSavescenarioWithoutLv1Node: 'Lv1ノードが欠けているため、シナリオを保存できません',
    __Lv1startingpointistheonlyitemthatmustbeset: 'Lv1の開始点は設定する必要がある唯一の項目です',
    __Addfailed: '追加に失敗しました',
    __updatecompleted: '更新が完了しました',
    __Abouttojumpbacktothedialogsettingpage: '対話設定ページに戻ります',
    __oopsSomethingwentwrong: '申し訳ありません、エラーが発生しました',
    __JudgingCategory: 'カテゴリを判定',
    __CompareFields: 'フィールドを比較',
    __CompareLINEID: 'LINE IDを比較',
    __CompareFilter: '篩選條件',
    __ExportType:'輸出格式',
    __IsExport:'是否輸出',
    __pleaseEnter: "請選擇",
    __tokenComment:"(如果寫入lookup 到別的app、要輸入所有app的token)",
    __AutoMaxPlus1:"自動產生(max+1)"
}
